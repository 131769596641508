import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import SelectTsStep from './SelectTsStep';
import SetProfileStep from './SetProfileStep';
import AppStep from './AppStep';
import SelectDateStep from './SelectDateStep';
import ConfirmStep from './ConfirmStep';
import CompletedStep from './CompletedStep';

import rootStore from '../../store';
const { bookingProcessStore } = rootStore;

function BookingProcess() {
  const { state } = useLocation();

  useEffect(() => {
    const steps: ReactElement[] = [
      <SelectTsStep />,
      <SetProfileStep />,
      <AppStep />,
      <SelectDateStep />,
      <ConfirmStep />,
      <CompletedStep />,
    ];

    bookingProcessStore.initBooking(steps, state);

    return () => bookingProcessStore.resetData();
  }, [state]);

  return (
    <Container className="flex-grow-1 pb-5">
      <Row className="justify-content-center">
        <Col md={6}>{bookingProcessStore.steps && bookingProcessStore.steps[bookingProcessStore.currentStep]}</Col>
      </Row>
    </Container>
  );
}

export default observer(BookingProcess);
