import client from '../../httpClient/clientV1';
import { UserStatus } from './types';

export type Profile = {
  email: string;
  email_verified?: boolean;
  phone_number: string;
  preferred_username?: string;
  status: UserStatus | null;
  sub?: string;
};

export type ProfileKeys = keyof Profile;

export type ProfileShort = Pick<Profile, 'email' | 'phone_number'>;

export type ProfileShortKeys = keyof ProfileShort;

export type UpdateProfileRequest = {
  [key: string]: string;
};

async function fetchProfile() {
  const response = await client.get(
    `${process.env.REACT_APP_KEYCLOAK_API_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/userinfo`
  );

  return response.data;
}

async function updateProfile(user: UpdateProfileRequest) {
  const response = await client.put('/user', user);
  return response.data;
}

export default Object.assign({
  fetchProfile,
  updateProfile,
});
