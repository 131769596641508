import { useEffect, useState, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Form, Col, Container, Row } from 'react-bootstrap';
// import { Editor, EditorState } from 'draft-js';
import { useTranslation } from 'react-i18next';
import { uid } from 'uid';
// import { dictionaryCategories } from '../../api';
import InputText from '../../../../components/InputText';
import Button from '../../../../components/Button';

import store from './../../../../store';
import { CreateMappRequest, UpdateMappRequest } from '../../../Mapp/api';
// import { fetchTransportById } from '../../../Transport/api';
const { mappStore } = store;

type RouterParams = {
  id: string;
};

type AppFields = {
  name: string;
  address: string;
  partPp: string;
  platformAddress: string;
  rushHour: string;
  workingHours: string;
  // entryPoint: string;
  // country: string;
};

const initialFieldValues: AppFields = {
  name: '',
  address: '',
  partPp: '',
  platformAddress: '',
  rushHour: '',
  workingHours: '',
  // name: '',
  // entryPoint: '',
  // country: '',
  // address: '',
  // platformAddress: '',
  // rushHour: '',
  // workingHours: '',
};

function DictionaryCategorySingle() {
  const { id } = useParams<RouterParams>();
  const { t } = useTranslation(['transport', 'common']);

  const navigate = useNavigate();

  const [mapp, setMapp] = useState<AppFields>(initialFieldValues);

  // const [editorState, setEditorState] = useState(() => EditorState.createWithContent(''));

  const fetchMapptById = useCallback(async () => {
    if (!id) return;

    const mappById = await mappStore.fetchMappById(id);

    if (!mappById) return;

    const fields = {
      name: mappById.name,
      address: mappById.address,
      partPp: mappById.partPp,
      rushHour: mappById.rushHour,
      workingHours: mappById.workingHours,
      platformAddress: '',
      // name: mappById.name,
      // entryPoint: mappById.partPp?.split(' ')[0] || '',
      // country: mappById.partPp?.split(' ')[1].replace(/\(|\)/g, '') || '',
      // address: mappById.address,
      // rushHour: mappById.rushHour,
      // workingHours: mappById.workingHours,
    };

    setMapp(fields);
    // setEditorState(mappById.address);
  }, [id]);

  useEffect(() => {
    fetchMapptById();
  }, [fetchMapptById, id]);

  const onChangeHandler = (key: string, value: string) => {
    const updateValue = { ...mapp, [key]: value };

    setMapp(updateValue);
  };

  const onSaveAppHandler = async () => {
    if (id) {
      // update
      const params: UpdateMappRequest = {
        name: mapp.name,
        boundaryPp: mapp.partPp,
        address: mapp.address,
        platformAddress: mapp.platformAddress,
        rushHour: mapp.rushHour,
      };

      const updatedMapp = await mappStore.updateMapp(id, params);

      if (updatedMapp) {
        navigate('/dictionary/app');
      }
    } else {
      // create
      const params: CreateMappRequest = {
        id: uid(32),
        name: mapp.name,
        boundaryPp: mapp.partPp,
        address: mapp.address,
        platformAddress: mapp.platformAddress,
        rushHour: mapp.rushHour,
      };

      const newMapp = await mappStore.createMapp(params);

      if (newMapp) {
        navigate('/dictionary/app');
      }
    }
  };

  return (
    <Container className="flex-grow-1 pb-3 pb-sm-5">
      <Row>
        <Col md={9}>
          <Link to="/dictionary/app" className="link-back my-4">
            {t('back', { ns: 'common' })}
          </Link>
          <h2 className="mt-40px mb-4">Редактирование АПП</h2>

          <div className="p-4 shadow-main rounded">
            <Row>
              <Col md={7}>
                <Row>
                  <Col md={12} className="pb-4">
                    <InputText
                      label="Наименование"
                      placeholder="Введите наименование"
                      value={mapp?.name}
                      onChange={(e) => onChangeHandler('name', e.target.value)}
                      clear={() => onChangeHandler('name', '')}
                      disabled={mappStore.isLoading}
                    />
                  </Col>
                  <Col md={12} className="pb-4">
                    <InputText
                      label="Сопредельный пункт пропуска и страна"
                      placeholder="Введите пункт пропуска"
                      value={mapp.partPp}
                      onChange={(e) => onChangeHandler('partPp', e.target.value)}
                      clear={() => onChangeHandler('partPp', '')}
                      disabled={mappStore.isLoading}
                    />
                  </Col>
                  {/* <Col md={6} className="pb-4">
                    <InputText
                      label="Страна"
                      placeholder="Введите страну"
                      value={mapp.country}
                      onChange={(e) => onChangeHandler('country', e.target.value)}
                      clear={() => onChangeHandler('country', '')}
                      disabled={mappStore.isLoading}
                    />
                  </Col> */}
                  <Col md={12} className="pb-4">
                    <Form.Label htmlFor={id}>Адрес пункта пропуска</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Введите адрес пункта пропуска"
                      rows={6}
                      value={mapp.address}
                      onChange={(e) => onChangeHandler('address', e.target.value)}
                      disabled={mappStore.isLoading}
                    />
                    {/* <Editor editorState={editorState} onChange={setEditorState} /> */}
                  </Col>
                  {/* <Col md={12} className="pb-4">
                    <Form.Label htmlFor={id}>Адрес площадки накопителя</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Введите адрес площадки накопителя"
                      rows={6}
                      value={mapp.rushHour}
                      onChange={(e) => onChangeHandler('rushHour', e.target.value)}
                      disabled={mappStore.isLoading}
                    />
                  </Col> */}
                  <Col md={12} className="pb-4">
                    <Form.Label htmlFor={id}>Адрес площадки накопителя</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Введите адрес площадки накопителя"
                      rows={10}
                      value={mapp.platformAddress}
                      onChange={(e) => onChangeHandler('platformAddress', e.target.value)}
                      disabled={mappStore.isLoading}
                    />
                  </Col>
                  <Col md={12} className="pb-4">
                    <Form.Label htmlFor={id}>Пиковые нагрузки</Form.Label>;
                    <Form.Control
                      as="textarea"
                      placeholder="Введите пиковые нагрузки"
                      rows={10}
                      value={mapp.rushHour}
                      onChange={(e) => onChangeHandler('rushHour', e.target.value)}
                      disabled={mappStore.isLoading}
                    />
                  </Col>
                  <Col md={6} className="pb-4">
                    <InputText
                      label="Время работы"
                      placeholder="Введите время работы"
                      value={mapp.workingHours}
                      onChange={(e) => onChangeHandler('workingHours', e.target.value)}
                      clear={() => onChangeHandler('workingHours', '')}
                      disabled={mappStore.isLoading}
                    />
                  </Col>
                </Row>
                <div>
                  <Button
                    type="submit"
                    variant="primary"
                    text={t('save', { ns: 'common' }) || ''}
                    className="me-4"
                    // disabled={!isValid()}
                    isLoading={mappStore.isLoading}
                    onClick={onSaveAppHandler}
                  />
                  <Button
                    variant="outline"
                    text={t('cancel', { ns: 'common' }) || ''}
                    className="me-4"
                    onClick={() => navigate('/dictionary/app')}
                    disabled={mappStore.isLoading}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default observer(DictionaryCategorySingle);
