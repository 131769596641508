import { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import Utils from './../../utils';

import rootStore from '../../store';
const { bookingProcessStore } = rootStore;

function CompletedStep() {
  const navigate = useNavigate();
  const { t } = useTranslation(['booking', 'common']);
  const [showQr, setShowQr] = useState(false);
  const [showCode, setShowCode] = useState(false);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
  });

  const qrCode = JSON.stringify(
    {
      qr_hash: bookingProcessStore.bookingResponse?.qrCode,
      // booking_id: bookingProcessStore.bookingResponse?.id,
      // car_grnz: bookingProcessStore.bookingResponse?.car.grnz,
      // booking_time: bookingProcessStore.bookingResponse?.timestamp,
      // cancel_code: bookingProcessStore.bookingResponse?.number,
    },
    null,
    2
  );

  const selectedDate = `${Utils.getDate(bookingProcessStore.selectedDay, 'd MMMM yyyy', true)} ${t(
    'year'
  )}, ${Utils.getSlot(bookingProcessStore.bookingResponse?.timestamp)}`;

  return (
    <div className="mt-4">
      <div className="shadow-main rounded-3 p-4 bg-white d-grid gap-4">
        <h3 className="m-0">{t('the_application_is_executed')}</h3>

        <div className="d-grid gap-3">
          <span>{t('you_booked_time')}</span>

          <div className="d-flex gap-3 align-items-start">
            <Icon name="mobile" className="flex-shrink-0 text-caption" />
            <span>{bookingProcessStore.selectedTransportLabel}</span>
          </div>
          <div className="d-flex gap-3 align-items-start">
            <Icon name="date" className="flex-shrink-0 text-caption" />
            <span>{selectedDate}</span>
          </div>
          <div className="d-flex gap-3 align-items-start">
            <Icon name="barrier" className="flex-shrink-0 text-caption" />
            <div>
              <span className="fw-bold">{bookingProcessStore.mappOption?.name}</span>
              <br />
              <span className="text-primary">{bookingProcessStore.mappOption?.address}</span>
            </div>
          </div>
        </div>

        <div>
          <h5>{t('whats_next')}</h5>
          <ol className="m-0">
            <li>{t('whats_next_1')}</li>
            <li>{t('whats_next_2')}</li>
          </ol>
        </div>

        <div>
          <span>
            {t('to_get_to_the_app_you_may_need_to')}{' '}
            <span className="text-primary cursor-pointer" onClick={() => setShowQr(true)}>
              {t('qr_code')}
            </span>
            , {t('it_can_be_found_in')}{' '}
            <Link to={`/booking/${bookingProcessStore.bookingResponse?.id}`}>{t('booking_card')}</Link>
          </span>
        </div>

        <div>
          <div className="d-flex gap-2 mb-2">
            <div className="my-auto" style={{ background: '#FED13E', height: '0.25rem', width: '1.5rem' }}></div>
            <h6 className="m-0 fw-bold">{t('rolse_of_reservation_on_the_spot')}</h6>
          </div>
          <span className="text-caption">
            {t('to_cancel_a_reservation_you_must_enter')}{' '}
            <span className="text-primary cursor-pointer" onClick={() => setShowCode(true)}>
              {t('code')}
            </span>{' '}
            {t('self_service_terminal_it_can_be_found_at')}{' '}
            <Link to={`/booking/${bookingProcessStore.bookingResponse?.id}`}>{t('booking_card')}</Link>
          </span>
        </div>

        <Button text={t('to_main', { ns: 'common' })} className="mx-auto" onClick={() => navigate('/booking')} />
      </div>

      <Modal
        show={showQr}
        onHide={() => setShowQr(false)}
        header={t('travel_for_the_route_to_the_app') || ''}
        body={
          <div className="d-grid gap-4" ref={componentRef}>
            <QRCode
              size={256}
              style={{ width: 150, height: 150 }}
              value={qrCode}
              viewBox={`0 0 256 256`}
              className="mt-2"
            />
            {t('print_or_save_the_QR_code')}
          </div>
        }
        footer={
          <div className="d-flex flex-column flex-sm-row gap-3 gap-lg-4 w-100">
            <Button onClick={handlePrint} text={t('print_the_qr_code')} />
            <Button variant="outline" onClick={() => setShowQr(false)} text={t('close', { ns: 'common' })} />
          </div>
        }
      />
      <Modal
        show={showCode}
        onHide={() => setShowCode(false)}
        header={t('booking_cancellation_code') || ''}
        body={
          <>
            <h4>{bookingProcessStore.bookingResponse?.number}</h4>
            {t('booking_cancellation_code_text')}
          </>
        }
        footer={
          <div className="d-flex flex-column flex-sm-row w-100">
            <Button onClick={() => setShowCode(false)} text={t('close', { ns: 'common' })} />
          </div>
        }
      />
    </div>
  );
}

export default CompletedStep;
