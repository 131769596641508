// import { BookingStore } from './store';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import QuizCard from '../../components/QuizCard';
import Select from '../../components/Select';
import Button from '../../components/Button';
import InputText from '../../components/InputText';
import Loader from '../../components/Loader';

import rootStore from '../../store';
import { TransportClass } from '../../models';
const { bookingProcessStore, transportStore } = rootStore;

function SelectTsStep() {
  const { t } = useTranslation(['booking', 'common']);
  const navigate = useNavigate();

  const isShowQuizCard = !bookingProcessStore.isLoading && !transportStore.transportList.length;
  const isShowSelect = !bookingProcessStore.isLoading && !!transportStore.transportList.length;

  const isShowTruckParams = bookingProcessStore.transportOption?.carClassId === TransportClass.TRUCK;
  const isShowBusParams = bookingProcessStore.transportOption?.carClassId === TransportClass.BUS;
  const isShowRegularRoute = bookingProcessStore.transportParams.routeType === 'regular';
  const isShowPassengersAvailability = bookingProcessStore.transportParams.passengersAvailability;

  const isDisabled = () => {
    if (bookingProcessStore.transportOption === null) return true;
    if (bookingProcessStore.transportParams === undefined) return true;

    const { carClassId } = bookingProcessStore.transportOption;
    const { routeType, routeNumber, passengersAvailability, passengersNumber } = bookingProcessStore.transportParams;

    if (carClassId === TransportClass.BUS && routeType === '') return true;
    if (routeType === 'regular' && routeNumber === '') return true;
    if (passengersAvailability && passengersNumber === '') return true;

    return false;
  };

  return (
    <div>
      <Link to="/booking" className="link-back my-4">
        {t('back', { ns: 'common' })}
      </Link>
      <h3 className="mb-32px">{t('choose_a_model_and_make')}</h3>

      {bookingProcessStore.isLoading && <Loader />}

      {isShowQuizCard && (
        <QuizCard text={t('add_a_new_vehicle')} className="mb-4" onClick={() => navigate('/transport/add')} />
      )}

      {isShowSelect && (
        <div className="pb-40px">
          <div className="bg-white p-4 rounded shadow-main">
            <Select
              name="category"
              isCollapse
              placeholder={t('vehicle')}
              options={transportStore.transportOptions}
              value={bookingProcessStore.transportOption}
              onChange={(e) => bookingProcessStore.setTransportOption(e)}
            />

            {isShowTruckParams && (
              <Form.Check
                type="checkbox"
                id="default-checkbox2"
                label={t('cargo_availability')}
                className="mt-4"
                checked={!!bookingProcessStore.transportParams.weight || undefined}
                onChange={(e) => bookingProcessStore.onChangeTransportParams('weight', e.target.checked)}
              />
            )}

            {isShowBusParams && (
              <div>
                <Row key="default-radio" className="my-4">
                  <Col md={6}>
                    <Form.Check
                      type="radio"
                      id="default-radio"
                      name="routeType"
                      label={t('regular_flight')}
                      value="regular"
                      onChange={(e) => bookingProcessStore.onChangeTransportParams('routeType', e.target.value)}
                    />
                  </Col>
                  <Col md={6}>
                    <Form.Check
                      type="radio"
                      id="disabled-default-radio"
                      name="routeType"
                      label={t('irregular_flight')}
                      value="notRegular"
                      onChange={(e) => bookingProcessStore.onChangeTransportParams('routeType', e.target.value)}
                    />
                  </Col>
                </Row>
                {isShowRegularRoute && (
                  <InputText
                    label={t('route_number')}
                    placeholder={t('route_number') || ''}
                    value={bookingProcessStore.transportParams.routeNumber as string}
                    onChange={(e) => bookingProcessStore.onChangeTransportParams('routeNumber', e.target.value)}
                    clear={() => bookingProcessStore.onChangeTransportParams('routeNumber', '')}
                    className="my-4"
                    style={{ width: '16.5rem' }}
                  />
                )}
                <Form.Check
                  type="checkbox"
                  id="default-checkbox"
                  label={t('availability_of_passengers')}
                  checked={!!bookingProcessStore.transportParams.passengersAvailability || undefined}
                  onChange={(e) =>
                    bookingProcessStore.onChangeTransportParams('passengersAvailability', e.target.checked)
                  }
                />
                {isShowPassengersAvailability && (
                  <InputText
                    label={t('number_of_passengers')}
                    placeholder={t('number_of_passengers') || ''}
                    value={bookingProcessStore.transportParams.passengersNumber as string}
                    onChange={(e) => bookingProcessStore.onChangeTransportParams('passengersNumber', e.target.value)}
                    clear={() => bookingProcessStore.onChangeTransportParams('passengersNumber', '')}
                    style={{ width: '16.5rem' }}
                    className="mt-4"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {!bookingProcessStore.isLoading && (
        <div className="d-flex justify-content-center">
          <Button
            text={t('proceed', { ns: 'common' })}
            className="d-inline-block m-auto"
            disabled={isDisabled()}
            onClick={() => bookingProcessStore.nextStep()}
          />
        </div>
      )}
    </div>
  );
}

export default observer(SelectTsStep);
