import { SelectOption } from '../../models';

export enum UserStatus {
  NOT_ESTABLISHED = 'not_established',
  PHYSICAL = 'physical',
  JURIDICAL = 'juridical',
}

export const userStatusOptions: SelectOption[] = [
  // { label: 'не установлен', value: UserStatus.NOT_ESTABLISHED },
  { label: 'physical', value: 'physical' },
  { label: 'juridical', value: 'juridical' },
];
