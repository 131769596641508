import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Form, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Loader from './../../../../components/Loader';

import { dictionaryCategories, DictionaryCategory } from '../../api';
import Icon from '../../../../components/Icon';

import store from './../../../../store';
import { Mapp } from '../../../Mapp/api';
import EmptyResult from '../../../../components/EmptyResult';
const { mappStore } = store;

const Thead = () => (
  <div className="d-flex">
    <div style={{ width: '30%' }} className="flex-shrink-0 text-secondary fs-7">
      Наименование
    </div>
    <div style={{ width: '30%' }} className="flex-shrink-0 text-secondary fs-7">
      Сопредельный ПП
    </div>
    <div style={{ width: '15%' }} className="flex-shrink-0 text-secondary fs-7">
      Добавлен
    </div>
    <div style={{ width: '15%' }} className="flex-shrink-0 text-secondary fs-7">
      Изменён
    </div>
    <div style={{ width: '10%' }} className="flex-shrink-0 text-center text-secondary fs-7">
      Действия
    </div>
  </div>
);

const TRow = ({ mapp }: { mapp: Mapp }) => (
  <div className="d-flex">
    <div style={{ width: '30%' }} className="flex-shrink-0 text-primary">
      {mapp.name}
    </div>
    <div style={{ width: '30%' }} className="flex-shrink-0">
      {mapp.partPp}
    </div>
    <div style={{ width: '15%' }} className="flex-shrink-0">
      -
    </div>
    <div style={{ width: '15%' }} className="flex-shrink-0">
      -
    </div>
    <div style={{ width: '10%' }} className="flex-shrink-0 d-flex justify-content-between align-items-center px-12px">
      <Link to={`/dictionary/app/${mapp.id}`} style={{ color: '#0B1F33' }}>
        <Icon name="edit" className="cursor-pointer" />
      </Link>
      <Icon name="trash" style={{ position: 'relative', top: 2, left: 6, opacity: 0.3 }} />
    </div>
  </div>
);

function DictionaryAppList() {
  const { t } = useTranslation(['transport', 'common']);

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    mappStore.fetchMapps();
  }, []);

  const { mapps, isLoading } = mappStore;

  const filteredMapps = mapps.filter((mapp: Mapp) => mapp.name.toLowerCase().includes(searchQuery.toLowerCase()));

  const hasMapps = !!filteredMapps?.length;

  return (
    <Container className="flex-grow-1 pb-3 pb-sm-5">
      <Row>
        <Col md={9}>
          <Link to="/dictionary" className="link-back my-4">
            {t('back', { ns: 'common' })}
          </Link>
          <h2 className="mt-40px mb-4">Справочник АПП</h2>

          <div className="bg-white rounded shadow-main" style={{ marginBottom: '4rem' }}>
            {/* <div className="d-flex p-4" style={{ borderBottom: `${dictionaries?.length ? 0 : '1px'} solid #E1E1E1` }}> */}
            <div className="d-flex p-4" style={{ borderBottom: '1px solid #E1E1E1' }}>
              <Form.Control
                type="search"
                placeholder="Поиск"
                className="flex-grow-1"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <Link
                to={'/dictionary/app/add'}
                style={{ whiteSpace: 'nowrap' }}
                className="d-flex align-items-center px-40px"
              >
                Добавить АПП
              </Link>
            </div>

            {isLoading && <Loader />}

            {!isLoading && hasMapps && (
              <div className="d-flex flex-column gap-4 p-4">
                <Thead />
                {filteredMapps?.map((mapp: Mapp, index) => (
                  <div key={index}>
                    <TRow mapp={mapp} />
                  </div>
                ))}
              </div>
            )}

            {!isLoading && !hasMapps && <EmptyResult text="Нет АПП по запросу" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default observer(DictionaryAppList);
