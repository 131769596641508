import { ForwardedRef, forwardRef } from 'react';
import { Button as BSButton, ButtonProps as BSButtonProps } from 'react-bootstrap';
import classNames from 'classnames';

type ButtonVariant = 'primary' | 'outline' | 'link';
type ButtonProps = BSButtonProps & {
  variant?: ButtonVariant;
  isLoading?: boolean;
  text: string;
};

function Button(
  { isLoading, className, text, variant = 'primary', ...props }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const classes = classNames(className, { 'is-loading': isLoading });

  const paddingX = text?.length < 16 ? '2.5rem' : '1.5rem';

  return (
    <BSButton
      ref={ref}
      className={classNames(classes, { spinner: isLoading })}
      variant={`seq-${variant}`}
      style={{ paddingLeft: paddingX, paddingRight: paddingX }}
      {...props}
    >
      {text}
    </BSButton>
  );
}

export type { ButtonVariant, ButtonProps };
export default forwardRef(Button);
