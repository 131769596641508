import { makeAutoObservable, action } from 'mobx';
import { SelectOptionExtended } from '../../models';
import { RootStore } from '../../store';
import Api, { Transport } from './api';

export class TransportStore {
  rootStore: RootStore;

  transportList: Transport[] = [];
  isLoading = true;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  async fetchTransport() {
    this.setLoading(true);

    await Api.fetchTransport()
      .then(
        action('fetchSuccess', (response: { data: Transport[] }) => {
          this.setTransport(response.data);
        }),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setLoading(false));
  }

  setTransport(transportList: Transport[]) {
    this.transportList = transportList;
  }

  async deleteTransportById(transportId: string) {
    this.setLoading(true);

    return await Api.deleteTransport(transportId)
      .then(
        action('fetchSuccess', (response: { data: Transport }) => response.data),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setLoading(false));
  }

  async fetchTransportById(id: string) {
    this.setLoading(true);

    return await Api.fetchTransportById(id)
      .then(
        action('fetchSuccess', (response: any) => response.data),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setLoading(false));
  }

  // async createTransport(transport: Transport) {
  async createTransport(transport: any) {
    this.setLoading(true);

    return await Api.createTransport(transport)
      .then(
        action('fetchSuccess', (response: { data: Transport[] }) => response.data),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setLoading(false));
  }

  // async updateTransport(id: string, transport: Transport) {
  async updateTransport(id: string, transport: any) {
    this.setLoading(true);

    return await Api.updateTransport(id, transport)
      .then(
        action('fetchSuccess', (response: { data: Transport[] }) => response.data),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setLoading(false));
  }

  get transportOptions(): SelectOptionExtended[] {
    return this.rootStore.transportStore.transportList.map((transport) => ({
      label: `${transport.name} ${transport.grnz}`,
      value: transport.grnz,
      ...transport,
    }));
  }
}
