export type BaseField = {
  value: string | boolean;
  error: string;
};

export type BaseFieldExtended = BaseField & {
  [key: string]: string | boolean;
};

export type SelectOption = {
  label: string;
  value: string;
};

export type SelectOptionExtended = SelectOption & {
  [key: string]: string | boolean;
};

export type Slot = {
  year: number;
  month: number;
  day: number;
  hours: number[];
};

export const UserType = {
  UserType_UNSPECIFIED: 'Не выбран',
  UserType_INDIVIDUAL: 'Физическое лицо',
  UserType_ENTITY: 'Юридическое лицо',
};

export type UserTypeKeys = keyof typeof UserType;

export enum TransportClass {
  PASSENGER = '1',
  TRUCK = '2',
  BUS = '3',
  BIKE = '4',
}
