import Icon from '../Icon';

type Props = {
  text: string;
  // width?: number;
  // height?: number;
  color?: string;
};

const EmptyResult = ({
  text,
  //  width = 82,
  //  height = 82,
  color = '#C9D8FA',
}: Props) => (
  <>
    {/* TODO: need another solution! */}
    <div className="d-flex d-lg-none align-items-center p-3 p-md-32px">
      <Icon name="search_question" className="empty-result__icon pe-3" width={56} height={56} style={{ color }} />
      <h3 className="fs-5">{text}</h3>
    </div>

    <div className="d-none d-lg-flex align-items-center p-2 p-md-32px">
      <Icon name="search_question" className="pe-5" width={82} height={82} style={{ color }} />
      <h3>{text}</h3>
    </div>
  </>
);

export default EmptyResult;
