import { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import Select from './../../../../components/Select';
import InputText from './../../../../components/InputText';
import Button from './../../../../components/Button';
import { SelectOption } from '../../../../models';
import Utils from './../../../../utils';
import { Transport } from '../../api';

import rootStore from '../../../../store';
import { transportClassOptions } from '../../../../models/static';
const { transportStore } = rootStore;

type RouterParams = {
  id: string;
};

type TransportFields = Omit<Transport, 'id' | 'carClassId'>;

const initialValue: TransportFields = {
  grnz: '',
  model: '',
  name: '',
};

function TransportSingle() {
  const { id } = useParams<RouterParams>();
  const { t } = useTranslation(['transport', 'common']);

  const navigate = useNavigate();

  const [transport, setTransport] = useState<TransportFields>(initialValue);
  const [transportOption, setTransportOption] = useState<SelectOption | null>(null);

  const fetchTransportById = useCallback(async () => {
    if (!id) return;

    const transportById = await transportStore.fetchTransportById(id);

    if (!transportById) return;

    setTransport(transportById);
    setTransportOption(Utils.transportClassToOption(transportById.carClassId));
  }, [id]);

  useEffect(() => {
    fetchTransportById();
  }, [fetchTransportById, id]);

  const onChangeHandler = (key: string, value: string) => {
    const updateValue = { ...transport, [key]: value };

    setTransport(updateValue);
  };

  const onCategoryChangeHandler = (options: SingleValue<SelectOption>) => {
    setTransportOption(options);
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();

    if (transportOption === null) return;

    const requestParams = {
      ...transport,
      carClassId: transportOption.value,
    };

    if (id) {
      const successResponse = await transportStore.updateTransport(id, requestParams);

      if (successResponse) {
        toast.info(
          `${t('data_ts_updated', { ns: 'common' })} ${successResponse.grnz} ${t('updated', { ns: 'common' })}`
        );
        navigate('/transport');
      }
    } else {
      const successResponse = await transportStore.createTransport(requestParams);

      if (successResponse) {
        toast.info(`${t('ts_add_number', { ns: 'common' })} ${successResponse.grnz}`);
        navigate('/transport');
      }
    }
  };

  const isValid = () => {
    const isValidFields = Object.entries(transport).every(([_, value]) => value !== '');
    const isValideSelect = transportOption !== null;

    return isValidFields && isValideSelect;
  };

  return (
    <>
      <Container className="flex-grow-1 pb-3 pb-sm-5">
        <Row>
          <Col md={9}>
            <Link to="/transport" className="link-back my-4">
              {t('back', { ns: 'common' })}
            </Link>
            <h3 className="mb-4">{t('adding_a_vehicle')}</h3>
            <Form onSubmit={onSubmitHandler} className="p-4 shadow-main rounded">
              <Row>
                <Col md={7}>
                  <div className="pb-4">
                    <Select
                      label={t('category') || ''}
                      placeholder={t('select_category') || ''}
                      options={transportClassOptions}
                      value={transportOption}
                      formatOptionLabel={(option) => <>{t(option.label)}</>}
                      onChange={(option) => onCategoryChangeHandler(option)}
                      isDisabled={transportStore.isLoading}
                    />
                  </div>
                  <Row>
                    <Col md={6}>
                      <InputText
                        label={t('brand') || ''}
                        placeholder={t('enter_the_brand') || ''}
                        value={transport?.name as string}
                        onChange={(e) => onChangeHandler('name', e.target.value)}
                        clear={() => onChangeHandler('name', '')}
                        disabled={transportStore.isLoading}
                        className="pb-4"
                      />
                    </Col>
                    <Col md={6}>
                      <InputText
                        label={t('model') || ''}
                        placeholder={t('enter_the_model') || ''}
                        value={transport?.model as string}
                        onChange={(e) => onChangeHandler('model', e.target.value)}
                        clear={() => onChangeHandler('model', '')}
                        disabled={transportStore.isLoading}
                        className="pb-4"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <InputText
                        label={t('gosnomer') || ''}
                        placeholder={t('enter_the_license_plate') || ''}
                        value={transport.grnz as string}
                        onChange={(e) => onChangeHandler('grnz', e.target.value)}
                        clear={() => onChangeHandler('grnz', '')}
                        disabled={transportStore.isLoading}
                        className="pb-4"
                      />
                    </Col>
                  </Row>
                  <div className="d-flex flex-column flex-sm-row gap-4">
                    <Button
                      type="submit"
                      variant="primary"
                      text={t('save', { ns: 'common' }) || ''}
                      disabled={!isValid()}
                      isLoading={transportStore.isLoading}
                    />
                    <Button
                      variant="outline"
                      text={t('cancel', { ns: 'common' }) || ''}
                      onClick={() => navigate('/transport')}
                      disabled={transportStore.isLoading}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default observer(TransportSingle);
