import { makeAutoObservable } from 'mobx';
import { SelectOption } from '../../../../../models';
import {
  statusOptions,
  timeOptions,
  transportClassOptions as classOptions,
  typeOptions,
} from '../../../../../models/static';
import { Booking } from '../../../api';

const transportClassOptions = [{ label: 'all', value: 'all' }, ...classOptions];

export class FilterStore {
  items: Booking[] = [];
  searchQuery = '';

  options: Record<string, SelectOption | null> = {
    typeOption: typeOptions[1],
    statusOption: statusOptions[0],
    timeOption: timeOptions[0],
    transportClassOption: transportClassOptions[0],
  };

  constructor() {
    makeAutoObservable(this);
  }

  initFilter(items: Booking[]) {
    this.items = items;
  }

  setSearchQuery(text: string) {
    this.searchQuery = text;
  }

  onChangeOption(key: string, option: SelectOption | null) {
    this.options[key] = option;
  }

  get filteredOptions() {
    return this.items
      .filter(
        (booking) =>
          booking.car.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          booking.car.model.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          booking.car.grnz.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          booking.mapp.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      )
      .filter((booking) => {
        if (this.options.typeOption?.value === 'all') return true;

        const nowDate = Date.now();
        const bookingDate = new Date(booking.timestamp).getTime();

        if (this.options.typeOption?.value === 'active') {
          return bookingDate > nowDate;
        }

        if (this.options.typeOption?.value === 'past') {
          return nowDate >= bookingDate;
        }
      })
      .filter((booking) => {
        if (this.options.statusOption?.value === 'all') return true;

        return booking.status === this.options.statusOption?.value;
      })
      .filter((booking) => {
        if (this.options.transportClassOption?.value === 'all') return true;

        return booking.car.carClass.carClassId === this.options.transportClassOption?.value;
      });
  }
}

const filterStore = new FilterStore();
export default filterStore;
