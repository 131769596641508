import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Utils from './../../utils';

function ClientLayout() {
  const location = useLocation();

  useEffect(() => {
    Utils.scrollTop();
  }, [location.pathname]);

  return <Outlet />;
}

export default ClientLayout;
