import { SelectOption, TransportClass } from '.';

export const transportClassOptions: SelectOption[] = [
  { value: TransportClass.PASSENGER, label: 'passenger' },
  { value: TransportClass.TRUCK, label: 'truck' },
  { value: TransportClass.BUS, label: 'bus' },
  { value: TransportClass.BIKE, label: 'bike' },
];

export const typeOptions: SelectOption[] = [
  {
    label: 'type_options_all', // Все
    value: 'all',
  },
  {
    label: 'type_options_active', // Активные
    value: 'active',
  },
  {
    label: 'type_options_past', // Прошедшие
    value: 'past',
  },
];

export const timeOptions: SelectOption[] = [
  {
    label: 'time_options_all', // За всё время
    value: '',
  },
  {
    label: 'time_options_week', // Неделя
    value: 'week',
  },
  {
    label: 'time_options_month', // Месяц
    value: 'month',
  },
  {
    label: 'time_options_half_year', // Полгода
    value: 'six_month',
  },
  {
    label: 'time_options_year', // Год
    value: 'year',
  },
  // {
  //   label: 'Указать период',
  //   value: 'Указать период',
  // },
];

export const statusOptions: SelectOption[] = [
  {
    label: 'status_options_all', // Все статусы
    value: 'all',
  },
  {
    label: 'status_options_on_review', // На проверке
    value: '10',
  },
  {
    label: 'status_options_register', // Зарегистрировано
    value: '20',
  },
  {
    label: 'status_options_confirm', // Заявка не_подтверждена
    value: '30',
  },
  {
    label: 'status_options_completed', // Завершено
    value: '40',
  },
  {
    label: 'status_options_cancel', // Отменено
    value: '50',
  },
];

export const NOTIFICATION_CATEGORIES: SelectOption[] = [
  {
    label: 'notificationAll',
    value: 'all',
  },
  {
    label: 'notificationNews',
    value: '0',
  },
  {
    label: 'notificationBooking',
    value: '1',
  },
  {
    label: 'notificationProfile',
    value: '2',
  },
];
