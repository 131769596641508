// import store from './../store';
// const { authStore } = store;
import store from './../store/';
const { authStore } = store;

export const eventLogger = (event: unknown, error: unknown) => {
  console.log('onKeycloakEvent', event, error);
};

export const tokenLogger = (tokens: Record<string, string>) => {
  console.log('onKeycloakTokens', tokens);

  const { token } = tokens;
  localStorage.setItem('seq_kk_token', token);
  window.dispatchEvent(new Event('storage'));

  authStore.setAccount(token);
};
