import { ForwardedRef, forwardRef } from 'react';
import { Button as BSButton, ButtonProps as BSButtonProps } from 'react-bootstrap';
import Icon from '../Icon';

type ButtonProps = BSButtonProps & {
  text: string;
};

function QuizCard({ className, text, ...props }: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) {
  const classes = `d-flex justify-content-between py-4 px-32px shadow-main ${className}`;

  return (
    <BSButton ref={ref} variant="seq-link" className={classes} {...props}>
      {text}
      <Icon name="small_right" width={9} />
    </BSButton>
  );
}

export type { ButtonProps };
export default forwardRef(QuizCard);
