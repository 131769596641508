import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../components/Spinner';
import { userStatusOptions } from '../types';

import rootStore from '../../../store';
const { profileStore } = rootStore;

function Info() {
  const { t } = useTranslation(['profile', 'common']);

  const status =
    userStatusOptions.find((option) => option.value === profileStore.profile?.status)?.label || 'undetermined';

  return (
    <div className="p-4 shadow-main rounded-4 custom-pointer bg-white d-grid gap-4 my-2 my-lg-0">
      <div className="d-grid gap-1">
        <span className="text-muted">{t('status')}</span>
        <span className="my-auto me-4">
          {profileStore.isLoading ? <Spinner size={24} className="m-auto text-primary" /> : t(status)}
        </span>
      </div>
      <Link to="/profile/status" className="link-primary fs-6">
        {t('edit', { ns: 'common' })}
      </Link>
    </div>
  );
}

export default observer(Info);
