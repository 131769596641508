import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import { SelectOption } from '../../../models';
import { userStatusOptions } from '../types';
import rootStore from '../../../store';
const { profileStore } = rootStore;

function Status() {
  const { t } = useTranslation(['profile', 'common']);
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(null);

  useEffect(() => {
    profileStore.fetchProfile().then(() => {
      const option = userStatusOptions.find(({ value }) => value === profileStore.profile.status);

      if (!option) return;

      setSelectedOption(option);
    });
  }, []);

  const onUpdateHandler = async () => {
    const isSuccessResponse = await profileStore.updateProfile({ type: selectedOption?.value || '' });

    if (isSuccessResponse) {
      profileStore.fetchProfile();
      toast.info(t('data_changed', { ns: 'common' }));
      navigate('/profile/info');
    }
  };

  return (
    <Container className="flex-grow-1 pb-3 pb-sm-5">
      <Link to="/profile/info" className="link-back my-4">
        {t('back', { ns: 'common' })}
      </Link>
      <h3 className="mb-4">{t('editing_data')}</h3>
      <div className="p-3 p-md-4 shadow-main rounded-4 custom-pointer bg-white d-grid gap-4">
        <Row>
          <Col sx={12} sm={8} md={6} lg={6} xl={4}>
            <Select
              label={t('status')}
              placeholder={t('select_status')}
              options={userStatusOptions}
              value={selectedOption}
              formatOptionLabel={(option) => <>{t(option.label)}</>}
              onChange={(e) => setSelectedOption(e)}
            />
          </Col>
        </Row>

        <div className="d-flex flex-column flex-sm-row gap-3 gap-md-4">
          <Button
            variant="primary"
            onClick={onUpdateHandler}
            text={t('save', { ns: 'common' })}
            isLoading={profileStore.isLoading}
          />
          <Button
            variant="outline"
            onClick={() => navigate('/profile/info')}
            text={t('cancel', { ns: 'common' })}
            disabled={profileStore.isLoading}
          />
        </div>
      </div>
    </Container>
  );
}

export default observer(Status);
