import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  ru: {
    header: {
      title: 'Система резервирования времени пересечения государственной границы',
      site: 'srv-gg',
      booking: 'Бронирования',
      transport: 'Транспорт',
      reports: 'Отчеты',
      directory: 'Справочники',
      app: 'АПП',
      profile: 'Профиль',
      notification: 'Уведомления',
      logout: 'Выйти',
    },
    footer: {
      title: 'Система резервирования времени пересечения государственной границы',
      support: 'Служба поддержки:',
    },
    profile: {
      profile: 'Профиль',
      account: 'Учётная запись',
      data: 'Данные',
      transport: 'Транспорт',
      phone: 'Телефон',
      change: 'Изменить',
      number_change: 'Изменение номера',
      new_phone_number: 'Новый номер телефона',
      enter_phone_number: 'Введите номер телефона',
      status: 'Статус',
      select_status: 'Выберите статус',
      physical: 'Физическое лицо',
      juridical: 'Юридическое лицо',
      undetermined: 'не установлен',
      editing_data: 'Редактирование данных',
    },
    booking: {
      filters: 'Фильтры',
      booking: 'Бронирование',
      book: 'Забронировать',
      add_new_booking: 'Добавьте новое бронирование',
      no_reservations_were_found: 'Бронирований не найдено',
      type_options_all: 'Все',
      type_options_active: 'Активные',
      type_options_past: 'Прошедшие',
      time_options_all: 'За всё время',
      time_options_week: 'Неделя',
      time_options_month: 'Месяц',
      time_options_half_year: 'Полгода',
      time_options_year: 'Год',
      status_options_all: 'Все статусы',
      status_options_on_review: 'На проверке',
      status_options_register: 'Зарегистрировано',
      status_options_confirm: 'Заявка не подтверждена',
      status_options_completed: 'Завершено',
      status_options_cancel: 'Отменено',
      year: 'года',
      year_short: 'г',
      vehicle_data: 'Данные транспортного средства',
      category: 'Категория',
      plate_number: 'Госномер',
      app_data: 'Данные АПП',
      name: 'Наименование',
      address: 'Адрес',
      contacts: 'Контакты',
      phone: 'Телефон',
      email: 'Электронная почта',
      directions_app: 'Код для проезда к АПП',
      cancel_code: 'Код отмены',
      cancel_booking: 'Отменить бронирование',
      cancellation_of_booking: 'Отмена бронирования',
      confirm_cancellation_booking: 'Подтвердить отмену бронирования',
      reservations_canceled: 'Бронирование отменено',
      home: 'На главную',

      choose_a_model_and_make: 'Выберите модель и марку',
      vehicle: 'Транспортное средство',
      add_a_new_vehicle: 'Добавить новое транспортное средство',
      cargo_availability: 'Наличие груза',
      regular_flight: 'Регулярный рейс',
      irregular_flight: 'Нерегулярный рейс',
      route_number: 'Номер маршрута',
      availability_of_passengers: 'Наличие пассажиров',
      number_of_passengers: 'Количество пассажиров',
      provide_contact_information_for_the_driver: 'Укажите контактные данные водителя',
      enter_phone_number: 'Введите номер телефона',
      enter_email: 'Введите электронную почту',
      matches_the_contact_details_of_the_profile: 'Совпадает с контактными данными профиля',
      select_app: 'Выберите АПП',
      app: 'АПП',
      date: 'Дата',
      time: 'Время',
      confirmation_time_after_ime_expires: 'Время на подтверждение ограничено, по истечении времени',
      remaining: 'Осталось',
      select_a_date_and_time: 'Выберите дату и время',
      you_book_interval_in_time_zone_located: 'Вы бронируете интервал в том часовом поясе, где находится АПП',
      you_have_selected: 'Вы выбрали',
      the_application_is_executed: 'Заявка оформлена',
      you_booked_time: 'Вы забронировали время для проезда автомобильного пункта пропуска',
      whats_next: 'Что дальше',
      whats_next_1: 'На указанный номер телефона и электронную почту придут уведомления о бронировании',
      whats_next_2:
        'Необходимо приехать на площадку-накопитель за 30 минут до начала забронированного интервала, чтобы успеть пройти фиксацию приезда для дальнейшего проезда в сторону АПП',
      booking_cancellation_code: 'Код отмены бронирования',
      booking_cancellation_code_text:
        'Код необходим для отмены бронирования на площадке-накопителе через терминал самообслуживания. Отмена бронирования без кода доступна в карточке бронирования',
      rolse_of_reservation_on_the_spot: 'Отмена бронирования на месте',
      travel_for_the_route_to_the_app: 'Код для проезда к АПП',
      print_or_save_the_QR_code:
        'Распечатайте или сохраните QR-код себе на устройство. Он может потребоваться для проезда и выезда с площадки-накопителя перед пунктом пропуска',
      to_get_to_the_app_you_may_need_to: 'Для проезда к АПП может понадобиться',
      qr_code: 'QR-код',
      it_can_be_found_in: 'его можно найти в',
      booking_card: 'карточке бронирования',
      to_cancel_a_reservation_you_must_enter: 'Для отмены бронирования необходимо ввести',
      code: 'код',
      self_service_terminal_it_can_be_found_at: 'в терминале самообслуживания, его можно найти в',
      print_the_qr_code: 'Распечатать QR-код',
      confirm_application: 'Подтвердите заявку',
      confirmation_time_limited: 'Время на подтверждение ограничено, по истечении времени',
      you_return_previous_step: 'вы вернётесь на предыдущий шаг',
    },
    transport: {
      add_new_vehicle: 'Добавьте новое транспортное средство',
      vehicles_were_not_found: 'Транспортные средства не найдены',
      adding_a_vehicle: 'Добавление транспортного средства',
      removing_the_vehicle: 'Удаление транспортного средства',
      the_vehicle_is_removed: 'Транспортное средство удалено',
      to_the_list_of_transport: 'К списку транспорта',
      category: 'Категория',
      brand: 'Марка',
      model: 'Модель',
      gosnomer: 'Госномер',
      select_the_vehicle: 'Выберите транспортное средство',
      select_category: 'Выберите категорию',
      enter_the_brand: 'Введите марку',
      enter_the_model: 'Введите модель',
      enter_the_license_plate: 'Введите госномер',
      passenger: 'Легковое',
      truck: 'Грузовое',
      bus: 'Автобус',
      bike: 'Мотоцикл',
      all: 'Все',
    },
    class: {
      all: 'Все',
      passenger: 'Легковое',
      cargo: 'Грузовое',
      bus: 'Автобус',
      motorcycle: 'Мотоцикл',
    },
    login: {
      authorization: 'Авторизация',
      register: 'Зарегистрироваться',
      cant_enter: 'Не удаётся войти?',
      enter_email: 'Введите email',
      enter_password: 'Введите пароль',
      password: 'Пароль',
      login: 'Войти',
    },
    app: {
      title: 'АПП',
      where_will_you_cross_the_border: 'Где будете пересекать границу?',
      peak_loads: 'Пиковые нагрузки',
      hours_work: 'Часы работы',
      book: 'Забронировать',
    },
    notification: {
      notifications: 'Уведомления',
      no_notifications: 'У вас пока нет уведомлений',
      notificationAll: 'Все',
      notificationNews: 'Новости',
      notificationBooking: 'Бронирования',
      notificationProfile: 'Профиль',
      show: 'Показать',
      allRead: 'Все сообщения прочитаны',
    },
    // status: {
    //   on_review: 'На проверке',
    //   registered: 'Зарегистрировано',
    //   not_confirm: 'Заявка не подтверждена',
    //   completed: 'Завершено',
    //   canceled: 'Отменено',
    //   annulled: 'Аннулировано',
    //   hidden: 'Скрыто',
    //   early_arrived: 'Раннее прибытие',
    // },
    status: {
      created: 'На проверке', // 0
      planned: 'Зарегистрировано', // 10
      approved: 'Ожидание времени', // 20
      not_arrived: 'ТС не прибыло ко времени', // 30
      transit_to_app: 'Проезд к АПП', // 35
      completed: 'Завершено', // 40
      cancelled_by_booker: 'Отменено', // 50
      cancelled_by_operator: 'Аннулировано', // 60
      cancelled_by_system: 'Аннулировано', // 70
      absolutely_invisible: 'Скрыто', // 80
      early_arrived: 'Покинуть площадку', // 90
      not_approved: 'Не подтверждена', // 100
    },
    analytic: {
      reports: 'Отчёты',
      amsWorkload: 'Загруженность АПП',
      freightVehicles: 'Грузовые ТС',
      buses: 'Автобусы',
      vehicleReservations: 'Бронирования ТС',
    },
    common: {
      back: 'Назад',
      loading: 'Загрузка',
      search: 'Поиск',
      add: 'Добавить',
      save: 'Сохранить',
      cancel: 'Отменить',
      cancellation: 'Отмена',
      delete: 'Удалить',
      not_found: 'Не найдено',
      proceed: 'Продолжить',
      close: 'Закрыть',
      to_main: 'На главную',
      no_data: 'Нет данных',
      edit: 'Редактировать',
      confirm: 'Подтвердить',
      data_changed: 'Данные были изменены',
      data_ts_updated: 'Данные ТС номер',
      updated: 'обновлены',
      ts_add_number: 'Добавлено ТС номер',
    },
  },

  ////////////////////////////////////

  en: {
    header: {
      title: 'The reservation system for crossing the state border',
      site: 'srv-gg',
      booking: 'Booking',
      transport: 'Transport',
      reports: 'Reports',
      directory: 'Directory',
      app: 'APP',
      profile: 'Profile',
      notification: 'Notification',
      logout: 'Logout',
    },
    footer: {
      title: 'The system of reservation of time for crossing the state border',
      support: 'Support:',
    },
    profile: {
      profile: 'Profile',
      account: 'Account',
      data: 'Data',
      transport: 'Transport',
      phone: 'Phone',
      change: 'Change',
      number_change: 'Number change',
      new_phone_number: 'New phone number',
      enter_phone_number: 'Enter phone number',
      status: 'Status',
      select_status: 'Select status',
      physical: 'Individual',
      juridical: 'Legal person',
      undetermined: 'Undetermined',
      editing_data: 'Editing data',
    },
    booking: {
      filters: 'Filters',
      booking: 'Booking',
      book: 'Book',
      add_new_booking: 'Add new booking',
      no_reservations_were_found: 'No reservations were found',
      type_options_all: 'All',
      type_options_active: 'Active',
      type_options_past: 'Past',
      time_options_all: 'In all the time',
      time_options_week: 'Week',
      time_options_month: 'Month',
      time_options_half_year: 'Six months',
      time_options_year: 'Year',
      status_options_all: 'All statuses',
      status_options_on_review: 'On review',
      status_options_register: 'Registered',
      status_options_confirm: 'The application is not confirmed',
      status_options_completed: 'Completed',
      status_options_cancel: 'Canceled',
      year: 'year',
      year_short: 'y',
      vehicle_data: 'Vehicle data',
      category: 'Category',
      plate_number: 'Plate number',
      app_data: 'APP data',
      name: 'Name',
      address: 'Address',
      contacts: 'Contacts',
      phone: 'Phone',
      email: 'Email',
      directions_app: 'Directions code for the APP',
      cancel_code: 'Cancel code',
      cancel_booking: 'Cancel booking',
      cancellation_of_booking: 'Cancellation of booking',
      confirm_cancellation_booking: 'Confirm cancellation of booking',
      reservations_canceled: 'Reservations canceled',
      home: 'Home',

      choose_a_model_and_make: 'Choose a model and make',
      vehicle: 'Vehicle',
      add_a_new_vehicle: 'Add a new vehicle',
      cargo_availability: 'Cargo availability',
      regular_flight: 'Regular flight',
      irregular_flight: 'Irregular flight',
      route_number: 'Route number',
      availability_of_passengers: 'Availability of passengers',
      number_of_passengers: 'Number of passengers',
      provide_contact_information_for_the_driver: 'Provide contact information for the driver',
      enter_phone_number: 'Enter phone number',
      enter_email: 'Enter email',
      matches_the_contact_details_of_the_profile: 'Matches the contact details of the profile',
      select_app: 'Select APP',
      app: 'APP',
      date: 'Date',
      time: 'Time',
      confirmation_time_after_ime_expires: 'Confirmation time is limited, after the time expires',
      remaining: 'Remaining',
      select_a_date_and_time: 'Select a date and time',
      you_book_interval_in_time_zone_located: 'You book an interval in the time zone where the APT is located',
      you_have_selected: 'You have selected',
      the_application_is_executed: 'The application has been made out',
      you_booked_time: 'You have reserved time to pass the road border crossing',
      whats_next: 'Whats next',
      whats_next_1: 'The specified phone number and email will be notified of the booking',
      whats_next_2:
        'It is necessary to arrive at the parking lot 30 minutes before the start of the booked interval in order to have time to pass the fixation of arrival for further passage in the direction of the checkpoint',
      booking_cancellation_code: 'Cancellation code',
      booking_cancellation_code_text:
        'The code is required to cancel a reservation at the storage site via a self-service terminal. Cancellation without code is available in the booking card',
      rolse_of_reservation_on_the_spot: 'Cancellation of a reservation on site',
      travel_for_the_route_to_the_app: 'Directions to the APP',
      print_or_save_the_QR_code:
        'Print or save the QR code on your device. You may need it to travel to and from the storage site in front of the checkpoint',
      to_get_to_the_app_you_may_need_to: 'To get to the APP you may need to',
      qr_code: 'QR-code',
      it_can_be_found_in: 'it can be found in',
      booking_card: 'booking card',
      to_cancel_a_reservation_you_must_enter: 'To cancel a reservation you must enter',
      code: 'code',
      self_service_terminal_it_can_be_found_at: 'at a self-service terminal, it can be found at',
      print_the_qr_code: 'Print the QR code',
      confirm_application: 'Confirm application',
      confirmation_time_limited: 'Confirmation time is limited, after the time expires',
      you_return_previous_step: 'you will return to the previous step',
    },
    transport: {
      add_new_vehicle: 'Add a new vehicle',
      vehicles_were_not_found: 'Vehicles were not found',
      adding_a_vehicle: 'Adding a vehicle',
      removing_the_vehicle: 'Removing the vehicle',
      the_vehicle_is_removed: 'The vehicle is removed',
      to_the_list_of_transport: 'To the list of transport',
      category: 'Category',
      brand: 'Brand',
      model: 'Model',
      gosnomer: 'Gosnomer',
      select_the_vehicle: 'Select the vehicle',
      select_category: 'Select category',
      enter_the_brand: 'Enter the brand',
      enter_the_model: 'Enter the model',
      enter_the_license_plate: 'Enter the license plate',
      passenger: 'Passenger',
      truck: 'Truck',
      bus: 'Bus',
      bike: 'Bike',
      all: 'All',
    },
    class: {
      all: 'All',
      passenger: 'Passenger',
      cargo: 'Cargo',
      bus: 'Bus',
      motorcycle: 'Motorcycle',
    },
    login: {
      authorization: 'Authorization',
      register: 'Register',
      cant_enter: 'Can`t enter?',
      enter_email: 'Enter email',
      enter_password: 'enter password',
      password: 'Password',
      login: 'Login',
    },
    app: {
      title: 'APP',
      where_will_you_cross_the_border: 'Where will you cross the border?',
      peak_loads: 'Peak loads',
      hours_work: 'Hours of work',
      book: 'Book',
    },
    notification: {
      notifications: 'Notifications',
      no_notifications: 'You have no notifications yet',
      notificationAll: 'All',
      notificationNews: 'News',
      notificationBooking: 'Booking',
      notificationProfile: 'Profile',
      show: 'Show',
      allRead: 'All messages read',
    },
    // status: {
    //   on_review: 'On review',
    //   registered: 'Registered',
    //   not_confirm: 'The application is not confirmed',
    //   completed: 'Completed',
    //   canceled: 'Canceled',
    //   annulled: 'Annulled',
    //   hidden: 'Hidden',
    //   early_arrived: 'Early arrived',
    // },
    status: {
      created: 'On review',
      planned: 'Registered',
      approved: 'Waiting time',
      not_arrived: 'Annulled',
      transit_to_app: 'Travel to APP',
      completed: 'Completed',
      cancelled_by_booker: 'Cancelled',
      cancelled_by_operator: 'Cancelled',
      cancelled_by_system: 'Cancelled',
      absolutely_invisible: 'Hidden',
      early_arrived: 'Leave the site',
      not_approved: 'Not confirmed', // 100
    },
    analytic: {
      reports: 'Reports',
      amsWorkload: 'AMS workload',
      freightVehicles: 'Freight vehicles',
      buses: 'Buses',
      vehicleReservations: 'Vehicle reservations',
    },
    common: {
      back: 'Back',
      loading: 'Loading',
      search: 'Search',
      add: 'Add',
      save: 'Save',
      cancel: 'Cancel',
      cancellation: 'Cancellation',
      delete: 'Delete',
      edit: 'Edit',
      confirm: 'Confirm',
      proceed: 'Proceed',
      to_main: 'Home',
      data_changed: 'The data has been changed',
      data_ts_updated: 'Vehicle data number',
      updated: 'updated',
      ts_add_number: 'Added by TC number',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lang') || 'ru',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
