import { action, makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { RootStore } from '../../store';
import Api, { Notification } from './api';

export class NotificationStore {
  rootStore: RootStore;

  notifications?: Notification[];
  notification?: Notification;

  isLoading = false;
  isPolling = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setIsPolling(value: boolean) {
    this.isPolling = value;
  }

  async fetchNotifications() {
    this.setIsLoading(true);

    await Api.fetchNotifications()
      .then(
        action('fetchSuccess', (response: { notifications: Notification[] }) =>
          this.setNotifications(response.notifications)
        ),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  // setNotifications(notifications?: Notification[]) {
  //   this.notifications = notifications === undefined ? undefined : notifications.reverse();
  // }

  async longPollingNotifications() {
    this.setIsPolling(true);

    await Api.longPollingNotifications()
      .then(
        action('fetchSuccess', (response: { notifications: Notification[] }) => {
          // this.setNotifications(response.notifications)
          console.log('end polling');
        }),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsPolling(false));
  }

  setNotifications(notifications?: Notification[]) {
    this.notifications = notifications === undefined ? undefined : notifications.reverse();
  }

  async fetchNotificationById(id: string) {
    this.setIsLoading(true);

    await Api.fetchNotificationById(id)
      .then(
        action('fetchSuccess', (response: { notification: Notification }) =>
          this.setNotification(response.notification)
        ),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  async readNotifications(ids: string[]) {
    this.setIsLoading(true);

    return await Api.readNotifications(ids)
      .then(
        action('fetchSuccess', (response: any) => true),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  setNotification(notification?: Notification) {
    this.notification = notification;
  }

  get hasNotifications() {
    if (this.notifications === undefined) return false;

    return !!this.notifications.length;
  }

  get unredNotificationCount() {
    if (this.notifications === undefined) return 0;

    return this.notifications.filter((notification) => !notification.isRead).length;
  }

  // reset() {
  //   this.setNotifications();
  //   this.setNotification();
  // }
}
