import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloak, keycloakInitOptions } from './keycloak';
import { eventLogger, tokenLogger } from './keycloak/loggers';
import App from './App';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakInitOptions}
      onEvent={eventLogger}
      onTokens={tokenLogger}
      autoRefreshToken
    >
      <Suspense fallback={<>Загрузка приложения ...</>}>
        <App />
      </Suspense>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
