import { ReactElement } from 'react';
import { makeAutoObservable, action } from 'mobx';
import ApiBookingProcess from './api';
import { ProfileShort, ProfileShortKeys } from '../Profile/api';
import { SelectOptionExtended, Slot, TransportClass } from '../../models';
import { RootStore } from '../../store';
import { periods } from '../../mock';
import { format } from 'date-fns';
import { Booking } from '../Booking/api';
import Utils from '../../utils';

const initialTransportParams: Record<string, string | boolean> = {
  weight: '',
  routeType: '',
  routeNumber: '',
  passengersAvailability: '',
  passengersNumber: '',
};

const initialProfile: ProfileShort = {
  phone_number: '',
  email: '',
};

export class BookingProcessStore {
  rootStore: RootStore;

  steps: ReactElement[] | null = null;
  currentStep = 0;
  lastStep = 0;

  transportOption: SelectOptionExtended | null = null;
  transportParams = initialTransportParams;

  profile = initialProfile;

  mappOption: SelectOptionExtended | null = null;

  slots: Slot[] | null = [];
  selectedDay: Date | null = null;
  selectedHour: number | null = null;

  bookingResponse: Booking | null = null;

  isLoading = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  initBooking(steps: ReactElement[], mappOption?: SelectOptionExtended) {
    this.setIsLoading(true);

    Promise.all([
      this.rootStore.transportStore.fetchTransport(),
      this.rootStore.profileStore.fetchProfile(),
      this.rootStore.mappStore.fetchMapps(),
    ])
      .then(() => {
        if (this.rootStore.transportStore.transportList.length === 1) {
          this.setTransportOption(this.rootStore.transportStore.transportOptions[0]);
        }

        // this.setProfile(this.rootStore.profileStore.profile);

        if (mappOption) {
          this.setMappOption(mappOption);
          steps.splice(2, 1);
        }
        this.setLastStep(steps.length);
        this.initSteps(steps);

        console.log(steps);
      })
      .catch((error) => console.error(error))
      .finally(() => this.setIsLoading(false));
  }

  initSteps(steps: ReactElement[] | null) {
    this.steps = steps;
  }

  nextStep() {
    if (this.currentStep === this.lastStep) return;
    this.currentStep = this.currentStep + 1;
    Utils.scrollTop();
  }

  prevStep() {
    if (this.currentStep === 0) return;
    this.currentStep = this.currentStep - 1;
    Utils.scrollTop();
  }

  setCurrentStep(step: number) {
    this.currentStep = step;
  }

  setLastStep(step: number) {
    this.lastStep = step;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setTransportOption(transportOption: SelectOptionExtended | null) {
    this.transportOption = transportOption;
  }

  onChangeTransportParams(key: string, value: string | boolean) {
    this.transportParams[key] = value;
  }

  clearTransportParams() {
    this.transportParams = initialTransportParams;
  }

  // setProfile(fields: Profile | null) {
  //   if (fields === null) {
  //     this.profile = initialProfile;
  //     return;
  //   }

  //   const { phone_number, email } = fields;

  //   this.profile.phone_number = phone_number;
  //   this.profile.email = email;
  // }

  clearProfile() {
    this.profile = initialProfile;
  }

  onChangeProfile(key: ProfileShortKeys, value: string) {
    if (key === 'phone_number') {
      const number = value.replace('+', '').replace(/\D/, '');
      this.profile[key] = `+${number}`;
    } else {
      this.profile[key] = value;
    }
  }

  onToggleContactsData(isChecked: boolean) {
    if (!isChecked) return;

    this.profile.phone_number = this.rootStore.profileStore.profile.phone_number;
    this.profile.email = this.rootStore.profileStore.profile.email;
  }

  setMappOption(mappOption: SelectOptionExtended | null) {
    this.mappOption = mappOption;
  }

  getSlots() {
    this.setIsLoading(true);

    ApiBookingProcess.fetchSlots(this.bookingSlotsParams)
      .then(
        action('fetchSuccess', (response: { data: Slot[] }) => this.setSlots(response.data)),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  setSlots(days: Slot[] | null) {
    this.slots = days;
  }

  setSelectedDay(day: Date | null) {
    if (day === null) {
      this.selectedDay = null;
      return;
    }

    this.selectedDay = day;
  }

  setSelectedHour(hour: number | null) {
    this.selectedHour = hour;
  }

  postBooking() {
    if (this.selectedDay === null) return;
    if (this.selectedHour === null) return;
    if (this.mappOption === null) return;

    this.setIsLoading(true);

    const car = { grnz: this.transportOption?.grnz, id: this.transportOption?.id, ...this.bookingSlotsParams };

    const year = new Date(this.selectedDay).getFullYear();
    const month = new Date(this.selectedDay).getMonth();
    const day = new Date(this.selectedDay).getDate();
    const hour = this.selectedHour;

    const calcBookingTime = format(new Date(year, month, day, hour), 'yyyy-MM-dd HH:00');

    const params = {
      driver: {
        email: this.profile.email,
        phone: this.profile.phone_number,
      },
      car,
      bookingTime: calcBookingTime,
      mappId: this.mappOption.id,
    };

    ApiBookingProcess.postBooking(params)
      .then(
        action('fetchSuccess', (response: { data: Booking }) => {
          this.setBookingResponse(response.data);
          this.nextStep();
        }),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  setBookingResponse(bookingResponse: Booking | null) {
    this.bookingResponse = bookingResponse;
  }

  get includeDates() {
    if (this.slots === null) return [];

    return this.slots?.map((slot: Slot) => {
      return new Date(slot.year, slot.month - 1, slot.day);
    });
  }

  get periodsByDay() {
    if (this.slots === null) return;
    if (this.selectedDay === null) return;

    const year = new Date(this.selectedDay).getFullYear();
    const month = new Date(this.selectedDay).getMonth() + 1;
    const day = new Date(this.selectedDay).getDate();

    return this.slots.find((slot) => slot.year === year && slot.month === month && slot.day === day)?.hours;
  }

  get bookingSlotsParams() {
    if (this.transportOption === null) return;
    if (this.mappOption === null) return;

    const params: Record<string, any> = {
      mappId: this.mappOption.id,
    };

    const { carClassId } = this.transportOption;
    const { routeType, passengersNumber } = this.transportParams;

    if (carClassId === TransportClass.PASSENGER) {
      params.passengerCar = {};
    }

    if (carClassId === TransportClass.TRUCK) {
      params.cargo = {
        laden: !!this.transportParams.weight,
      };
    }

    if (carClassId === TransportClass.BUS) {
      params.bus = {
        flightType: routeType === 'regular' ? 'FlightType_REGULAR' : 'FlightType_IRREGULAR',
        countPass: passengersNumber ? Number(passengersNumber) : 0,
      };
    }

    if (carClassId === TransportClass.BIKE) {
      params.bike = {};
    }

    return params;
  }

  get selectedTransportLabel() {
    if (this.transportOption === null) return;
    const { name, model, grnz } = this.transportOption;

    return `${name} ${model} ${grnz}`;
  }

  // get selectedDateWithMonthName() {
  //   if (this.selectedDay === null) return;

  //   return format(new Date(this?.selectedDay), 'd MMMM', { locale: ru });
  // }

  get selectedHoursPeriod() {
    if (this.selectedHour === null) return '';

    return periods[this.selectedHour];
  }

  // get selectedWeekDayName() {
  //   if (this.selectedDay === null) return '';

  //   return format(new Date(this?.selectedDay), 'EEEE', { locale: ru });
  // }

  // get selectedYear() {
  //   if (this.selectedDay === null) return '';

  //   return format(new Date(this.selectedDay), 'yyyy', { locale: ru });
  // }

  resetData() {
    this.initSteps(null);
    this.setCurrentStep(0);
    this.setLastStep(0);
    this.setTransportOption(null);
    this.clearTransportParams();
    // this.setProfile(null);
    this.clearProfile();
    this.setMappOption(null);
    this.setSlots(null);
    this.setSelectedDay(null);
    this.setSelectedHour(null);
    this.setBookingResponse(null);
    this.setIsLoading(false);
  }
}
