import axios from 'axios';
import { toast } from 'react-toastify';

const clientV1 = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

clientV1.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('seq_kk_token');

  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
  }

  return config;
});

clientV1.interceptors.response.use(
  (response) => {
    const { method, url } = response.config;
    console.log(`[Axios ${method?.toUpperCase()} ${url}]`, response);
    return response;
  },
  (error) => {
    let message = String(error);
    console.log(error);
    if (axios.isAxiosError(error)) message = error.message;
    toast.error(message, { autoClose: false });
  }
);

export default clientV1;
