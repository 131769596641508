import client from '../../httpClient/clientV1';

export type Booking = {
  id: string;
  mapp: {
    id: string;
    address: string;
    partPp: string;
    rushHour: string;
    workingHours: string;
    name: string;
  };
  slotId: string;
  driver: {
    email: string;
    phone: string;
  };
  car: {
    grnz: string;
    name: string;
    model: string;
    carClass: {
      id: string;
      tsClassId: string;
      carClassId: string;
      name: string;
    };
    properties: {
      routeType: string;
      routeNum: number;
      passCount: number;
    };
  };
  timestamp: string;
  number: string;
  status: string;
  qrCode: string;
};

export const statusBookings: statusType = {
  '10': { color: '#2DC36A', icon: 'check', text: 'Новая' },
  '20': { color: '#2DC36A', icon: 'check', text: 'Прибыл на площадку' },
  '30': { color: '#E11432', icon: 'close', text: 'ТС не прибыло ко времени' },
  '40': { color: '#2DC36A', icon: 'finish', text: 'Завершена' },
  '50': { color: '#E11432', icon: 'close', text: 'Аннулирована' },
  '60': { color: '#E11432', icon: 'close', text: 'Аннулирована' },
  '70': { color: '#E11432', icon: 'close', text: 'Аннулирована' },
  '80': { color: '#E11432', icon: 'close', text: 'Скрыто' },
};

type RequestCancelBooking = {
  id: string;
  cancelCode: string;
};

type statusType = Record<
  string,
  {
    color: string;
    icon: string;
    text: string;
  }
>;

async function fetchBookings() {
  const response = await client.get<{ data: Booking[] }>('/bookings');
  return response.data;
}

async function fetchBookingById(id: string) {
  const response = await client.get<{ data: Booking }>(`/bookings/${id}`);
  return response.data;
}

async function cancelBooking(data: RequestCancelBooking) {
  const response = await client.post<{ status: boolean }>('/bookings_cancel', data);
  return response.data;
}

export default Object.assign({ fetchBookings, fetchBookingById, cancelBooking });
