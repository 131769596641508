import { AuthStore } from './authStore';
import { ProfileStore } from '../entity/Profile/store';
import { BookingStore } from '../entity/Booking/store';
import { BookingProcessStore } from '../entity/BookingProcess/store';
import { TransportStore } from '../entity/Transport/store';
import { MappStore } from '../entity/Mapp/store';
import { NotificationStore } from '../entity/Notification/store';
import { autorun, reaction } from 'mobx';

export class RootStore {
  authStore = new AuthStore(this);
  profileStore = new ProfileStore(this);
  bookingStore = new BookingStore(this);
  bookingProcessStore = new BookingProcessStore(this);
  transportStore = new TransportStore(this);
  mappStore = new MappStore(this);
  notificationStore = new NotificationStore(this);
}

const store = new RootStore();

// autorun(() => {
//   store.notificationStore.longPollingNotifications();
// });

// reaction(
//   () => store.notificationStore.isPolling,
//   (isPolling) => {
//     if (!isPolling) {
//       store.notificationStore.longPollingNotifications();
//     }
//   }
// );

// TODO: put in a separate module
reaction(
  () => store.authStore.userRole,
  (userRole) => {
    // if (userRole === 'users') {
    // store.notificationStore.fetchNotifications();
    // }
  }
);

reaction(
  () => store.bookingProcessStore.bookingResponse,
  (bookingResponse) => {
    // if (bookingResponse !== null) {
    //   store.notificationStore.fetchNotifications();
    // }
  }
);

export default store;
