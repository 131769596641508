import client from '../../httpClient/clientV2';

export type Notification = {
  body: string;
  createdAt: string;
  header: string;
  id: string;
  isRead: boolean;
  section: string;
};

async function fetchNotifications() {
  const response = await client.get('/notifications');
  return response.data;
}

async function fetchNotificationById(id: string) {
  const response = await client.get(`/notifications/${id}`);
  return response.data;
}

async function readNotifications(ids: string[]) {
  const response = await client.put('/notifications/read', { Ids: ids });
  return response.data;
}

async function longPollingNotifications() {
  const response = await client.get('/notifications/new');
  return response.data;
}

export default Object.assign({
  fetchNotifications,
  fetchNotificationById,
  readNotifications,
  longPollingNotifications,
});
