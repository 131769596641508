import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Icon from './../Icon';

const dashboards = [
  { dashboardId: '1', label: 'amsWorkload' },
  { dashboardId: '2', label: 'freightVehicles' },
  { dashboardId: '3', label: 'buses' },
  { dashboardId: '4', label: 'vehicleReservations' },
];

function AnalyticMenu() {
  const navigate = useNavigate();
  const { t } = useTranslation('analytic');

  return (
    <Dropdown align="end">
      <Dropdown.Toggle className="bg-transparent text-primary border-0 d-flex p-0">
        {t('reports')} <Icon name="small_down" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {dashboards.map((dashboard) => (
          <Dropdown.Item key={dashboard.dashboardId} onClick={() => navigate(`/dashboard/${dashboard.dashboardId}`)}>
            {t(dashboard.label)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default AnalyticMenu;
