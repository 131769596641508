import { useState, useEffect } from 'react';
import { Outlet, useLocation, Link } from 'react-router-dom';
import { Container, Col, Row, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DeleteModal from './components/DeleteModal';

import rootStore from '../../store';
const { profileStore } = rootStore;

function ProfileLayout() {
  const { t } = useTranslation('profile');
  const location = useLocation();

  const activeTab = location.pathname;

  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    profileStore.fetchProfile();

    return () => profileStore.resetValues();
  }, []);

  return (
    <>
      <Container className="flex-grow-1 pb-3 pb-sm-5">
        {/* <h1 className="my-3 my-lg-40px mx-0">{t('profile')}</h1> */}
        <h1 className="mt-5 mb-3 my-lg-40px mx-0">{t('profile')}</h1>

        <Row>
          <Col md={3}>
            <Nav activeKey={activeTab} className="flex-md-column gap-4 gap-md-2">
              <Nav.Link as={Link} to="/profile/account" eventKey="/profile/account" className="px-0">
                {t('account')}
              </Nav.Link>
              <Nav.Link as={Link} to="/profile/info" eventKey="/profile/info" className="px-0">
                {t('data')}
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/transport"
                // className="mt-2 py-2 px-0"
                style={{ color: '#1d5deb' }}
                className="px-0"
              >
                {t('transport')}
              </Nav.Link>
            </Nav>
          </Col>
          <Col md={9}>
            <Outlet />
            {/* <Link to="#" className="d-block link my-36px px-0" onClick={() => setIsShowModal(true)}>
              Удалить учётную запись
            </Link> */}
          </Col>
        </Row>
      </Container>
      <DeleteModal show={isShowModal} onClose={() => setIsShowModal(false)} />
    </>
  );
}

export default ProfileLayout;
