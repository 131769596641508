import Keycloak from 'keycloak-js';

export const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_API_URL,
  realm: String(process.env.REACT_APP_KEYCLOAK_REALM),
  clientId: 'account',
});

export const keycloakInitOptions = {
  pkceMethod: 'S256',
  checkLoginIframe: false,
  // redirectUri: `${process.env.REACT_APP_BASE_URL}/profile/account`,
};
