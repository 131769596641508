import { action, makeAutoObservable } from 'mobx';
import { RootStore } from '../../store';
import Api, { Booking } from './api';

// const filterParamsInitValue = {
//   period: '',
//   status: '',
//   category: '',
//   query: '',
// };

export class BookingStore {
  rootStore: RootStore;

  bookings: Booking[] = [];
  booking: Booking | null = null;

  isLoading = true;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  async fetchBookings() {
    this.setIsLoading(true);

    await Api.fetchBookings()
      .then(
        action('fetchSuccess', (response: { data: Booking[] }) => this.setBookings(response.data)),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  setBookings(bookings: Booking[]) {
    this.bookings = bookings.reverse();
  }

  fetchBookingById(id: string) {
    this.setIsLoading(true);

    Api.fetchBookingById(id)
      .then(
        action('fetchSuccess', (response: { data: Booking }) => this.setBooking(response.data)),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  setBooking(booking: Booking | null) {
    this.booking = booking;
  }

  cancelBooking(booking: Booking) {
    const requestParams = {
      id: booking.id,
      cancelCode: booking.number,
    };

    return Api.cancelBooking(requestParams)
      .then(
        action('fetchSuccess', () => true),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  // resetFilter() {
  //   this.filterParams = filterParamsInitValue;
  // }

  // setPeriodFilterParams(period: { value: string }) {
  //   const date = new Date();

  //   switch (period.value) {
  //     case 'week':
  //       this.filterParams.period = new Date(date.setDate(date.getDate() - 7)).toISOString();
  //       break;
  //     case 'month':
  //       this.filterParams.period = new Date(date.setMonth(date.getMonth() - 1)).toISOString();
  //       break;
  //     case 'six_month':
  //       this.filterParams.period = new Date(date.setMonth(date.getMonth() - 6)).toISOString();
  //       break;
  //     case 'year':
  //       this.filterParams.period = new Date(date.setMonth(date.getMonth() - 12)).toISOString();
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // setStatusFilterParams(status: { value: string }) {
  //   this.filterParams.status = status.value;
  // }

  // setCategoryFilterParams(carClassId: { value: string }) {
  //   this.filterParams.category = carClassId.value;
  // }

  // setQueryFilterParams(textSearch: string) {
  //   this.filterParams.query = textSearch;
  // }

  // get filterBookings() {
  //   let filteredBookings = this.bookings;

  //   if (this.filterParams.period) {
  //     filteredBookings = this.bookings.filter(
  //       (booking: BookingType) => new Date(booking.timestamp) > new Date(this.filterParams.period)
  //     );
  //   }

  //   if (this.filterParams.status) {
  //     filteredBookings = this.bookings.filter((booking: BookingType) =>
  //       booking.status.includes(this.filterParams.status)
  //     );
  //   }

  //   if (this.filterParams.category) {
  //     filteredBookings = this.bookings.filter((booking: BookingType) =>
  //       booking.car.carClass.carClassId.includes(this.filterParams.category)
  //     );
  //   }

  //   if (this.filterParams.query) {
  //     filteredBookings = filteredBookings.filter(
  //       (booking: BookingType) =>
  //         booking.car.grnz.includes(this.filterParams.query) ||
  //         booking.car.model.includes(this.filterParams.query) ||
  //         booking.car.name.includes(this.filterParams.query)
  //     );
  //   }
  //   return filteredBookings;
  // }
}
