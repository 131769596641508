import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Form, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { dictionaries, dictionaryCategories, DictionaryCategory } from '../../api';
import Icon from '../../../../components/Icon';
import InputText from '../../../../components/InputText';
import Button from '../../../../components/Button';

type RouterParams = {
  id: string;
};

function DictionaryCategoryList() {
  const { id } = useParams<RouterParams>();

  const category = dictionaryCategories.find((category) => category.id === id);

  const { t } = useTranslation(['transport', 'common']);

  const navigate = useNavigate();

  const [text, setText] = useState(category?.name || '');

  return (
    <Container className="flex-grow-1 pb-3 pb-sm-5">
      <Row>
        <Col md={9}>
          <Link to="/dictionary/category" className="link-back my-4">
            {t('back', { ns: 'common' })}
          </Link>
          <h2 className="mt-40px mb-4">Редактирование категории</h2>

          <div className="p-4 shadow-main rounded" style={{ marginBottom: '4rem' }}>
            <Row>
              <Col md={7}>
                <Row className="pb-4">
                  <Col md={6}>
                    <InputText
                      label="Наименование"
                      placeholder="Введите наименование"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      // clear={() => onChangeHandler('grnz', '')}
                      // disabled={transportStore.isLoading}
                    />
                  </Col>
                </Row>
                <div>
                  <Button
                    type="submit"
                    variant="primary"
                    text={t('save', { ns: 'common' }) || ''}
                    className="me-4"
                    // disabled={!isValid()}
                    // isLoading={transportStore.isLoading}
                    onClick={() => navigate('/dictionary/category')}
                  />
                  <Button
                    variant="outline"
                    text={t('cancel', { ns: 'common' }) || ''}
                    className="me-4"
                    onClick={() => navigate('/dictionary/category')}
                    // disabled={mappStore.isLoading}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default observer(DictionaryCategoryList);
