import { SelectOption } from './models';
import Icon from './components/Icon';
import { ReactNode } from 'react';
import { format as fnsFormat } from 'date-fns';
import { ru, enUS } from 'date-fns/locale';
import { periods } from './mock';
import { transportClassOptions } from './models/static';

const phoneFormat = (phone: string) => {
  if (phone[0] === '+') {
    return phone.replace(/[A-Za-zА-Яа-я]/g, '');
  } else {
    return '+' + phone.replace(/[A-Za-zА-Яа-я]/g, '');
  }
};

const pluralize = ({ count, one, few, many }: { count: number; one: string; few: string; many: string }) => {
  const n = Math.abs(count) % 100;

  if (n % 100 >= 11 && n % 100 <= 14) return many;

  switch (n % 10) {
    case 1:
      return one;
    case 2:
    case 3:
    case 4:
      return few;
    default:
      return many;
  }
};

const transportClassToOption = (transportClass: string): SelectOption => {
  const index = Number(transportClass) - 1;
  return transportClassOptions[index];
};

const getTransportIconByClass = (transportClass: string) => {
  const iconByTransportClass: Record<string, ReactNode> = {
    '1': <Icon name="legkovie" style={{ color: '#66727F' }} />,
    '2': <Icon name="gruzovie" style={{ color: '#66727F' }} />,
    '3': <Icon name="bus" style={{ color: '#66727F' }} />,
    '4': <Icon name="bike" style={{ color: '#66727F' }} />,
  };

  return iconByTransportClass[transportClass];
};

const getStatus = (statusCode: string) => {
  type statusType = Record<
    string,
    {
      icon: ReactNode;
      text: string;
    }
  >;

  // const statuses: statusType = {
  //   '10': { icon: <Icon name="check" style={{ color: 'green' }} />, text: 'on_review' },
  //   '20': { icon: <Icon name="check" style={{ color: 'green' }} />, text: 'registered' },
  //   '30': { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'not_confirm' },
  //   '40': { icon: <Icon name="finish" style={{ color: 'green' }} />, text: 'completed' },
  //   '50': { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'canceled' },
  //   '60': { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'annulled' },
  //   '70': { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'annulled' },
  //   '80': { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'Hidden' },
  //   '90': { icon: <Icon name="check" style={{ color: 'green' }} />, text: 'early_arrived' },
  // };

  const statuses: statusType = {
    '0': { icon: <Icon name="check" style={{ color: 'green' }} />, text: 'created' }, //-
    '10': { icon: <Icon name="check" style={{ color: 'green' }} />, text: 'planned' },
    '20': { icon: <Icon name="check" style={{ color: 'green' }} />, text: 'approved' },
    '30': { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'not_arrived' }, //-
    '35': { icon: <Icon name="check" style={{ color: 'green' }} />, text: 'transit_to_app' },
    '40': { icon: <Icon name="finish" style={{ color: 'green' }} />, text: 'completed' }, //-
    '50': { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'cancelled_by_booker' }, //-
    '60': { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'cancelled_by_operator' }, //-
    '70': { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'cancelled_by_system' }, //-
    '80': { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'absolutely_invisible' }, //-
    '90': { icon: <Icon name="check" style={{ color: 'green' }} />, text: 'early_arrived' },
    '100': { icon: <Icon name="close" style={{ color: 'red' }} />, text: 'not_approved' }, //-
  };

  return statuses[statusCode];
};

const getDate = (timestamp: string | null | undefined, format: string | any, noTimeZone?: boolean) => {
  if (!timestamp) return '';

  const lang = localStorage.getItem('lang') === 'ru' ? ru : enUS;

  if (noTimeZone) {
    return fnsFormat(new Date(timestamp), format, { locale: lang });
  }
  // refactor this
  const utcDate = new Date(timestamp);
  const dtDateOnly = new Date(utcDate.valueOf() + utcDate.getTimezoneOffset() * 60 * 1000);

  return fnsFormat(dtDateOnly, format, { locale: lang });
};

const getSlot = (timestamp: string) => {
  const index = new Date(timestamp).getUTCHours();
  return periods[index];
};

const getNotificationIconById = (notificationId: string) => {
  switch (notificationId) {
    case '0':
      return 'mail';
    case '1':
      return 'blank';
    case '2':
      return 'user_full';
    default:
      return 'mail';
  }
};

const getNotificationLabelById = (notificationId: string) => {
  switch (notificationId) {
    case '0':
      return 'Новости';
    case '1':
      return 'Бронивароние';
    case '2':
      return 'Профиль';
    default:
      return 'Новости';
  }
};

const scrollTop = () => {
  window.scrollTo({ top: 0 });
};

export default Object.assign({
  phoneFormat,
  pluralize,
  transportClassToOption,
  getTransportIconByClass,
  getStatus,
  getDate,
  getSlot,
  getNotificationIconById,
  getNotificationLabelById,
  scrollTop,
});
