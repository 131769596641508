import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Form, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import { Mapp } from '../../api';

import rootStore from '../../../../store';
import EmptyResult from '../../../../components/EmptyResult';
const { mappStore } = rootStore;

function MappPage() {
  const navigate = useNavigate();
  const { t } = useTranslation(['app', 'common']);

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    mappStore.fetchMapps();
  }, []);

  const filteredMapps = mappStore.mapps.filter(
    (mapp) =>
      mapp.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
      mapp.partPp.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const AppRow = ({ app }: { app: Mapp }) => {
    const { name, address, partPp, rushHour, workingHours } = app;

    const point = partPp?.split(' ')[0] || '-';
    const country = partPp?.split(' ')[1] || '-';
    const rushHourFormatted = rushHour?.replaceAll('\n', '</br>') || '-';
    const workingHoursFormatted = workingHours?.replaceAll('\n', '</br>') || '-';

    return (
      <div
        className="p-4 border-top"
        style={{ borderColor: '#E1E1E1' }}
        // onClick={() => navigate(`/app-info/${app.id}`)}
      >
        <Row className="mb-0 mb-lg-3">
          <Col xs={12} lg={4} className="mb-4 mb-lg-0">
            <h6>{name}</h6>
            <span>{address}</span>
          </Col>
          <Col xs={12} lg={2} className="mb-4 mb-lg-0">
            <h6>{point}</h6>
            <span>{country}</span>
          </Col>
          <Col xs={12} lg={4} className="mb-4 mb-lg-0">
            <h6>{t('peak_loads')}</h6>
            <div dangerouslySetInnerHTML={{ __html: rushHourFormatted }} />
          </Col>
          <Col xs={12} lg={1} className="mb-4 mb-lg-0">
            <h6>{t('hours_work')}</h6>
            <span dangerouslySetInnerHTML={{ __html: workingHoursFormatted }} />
          </Col>
        </Row>
        <div className="d-flex flex-column flex-sm-row">
          <Button
            text={t('book')}
            disabled={!app.id}
            onClick={() => navigate('/booking-process', { state: { label: app.name, value: app.id, ...app } })}
          />
        </div>
      </div>
    );
  };

  return (
    <Container className="flex-grow-1 pb-3 pb-sm-5">
      <Row>
        <Col md={9}>
          <h2 className="mt-40px mb-4">{t('title')}</h2>

          <div className="bg-white rounded shadow-main">
            <div
              className="d-flex p-3 p-lg-4"
              style={{ borderBottom: `${filteredMapps?.length ? 0 : '1px'} solid #E1E1E1` }}
            >
              <Form.Control
                type="search"
                placeholder={t('search', { ns: 'common' }) || ''}
                className="flex-grow-1"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            {mappStore.isLoading && <Loader />}

            {!mappStore.isLoading && filteredMapps.map((app: Mapp, index: number) => <AppRow key={index} app={app} />)}

            {!mappStore.isLoading && !filteredMapps.length && <EmptyResult text="АПП не найдено" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default observer(MappPage);
