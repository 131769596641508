import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';
import { ReactComponent as LanguageIcon } from '../../assets/language.svg';

const languages = [
  { lang: 'ru', text: 'Русский' },
  { lang: 'en', text: 'English' },
];

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('lang', language);
  };

  return (
    <Dropdown align="end">
      <Dropdown.Toggle className="bg-transparent text-primary border-0 d-flex p-0">
        <LanguageIcon />
        <Icon name="small_down" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {languages.map((language, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => changeLanguage(language.lang)}
            // active={localStorage.getItem('lang') === language.lang}
          >
            {language.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LanguageSwitcher;
