import { action, makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import Api, { Profile } from './api';
import { RootStore } from '../../store';
import { UserStatus } from './types';

const initFields: Profile = {
  email: '',
  phone_number: '',
  status: null,
};

export class ProfileStore {
  rootStore: RootStore;

  profile = initFields;
  isLoading = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  async fetchProfile() {
    this.setLoading(true);

    await Api.fetchProfile()
      .then(
        action('fetchSuccess', (response: Profile) => {
          this.setProfile(response);
        }),
        action('fetchError', (error: unknown) => {
          console.error(error);
        })
      )
      .finally(() => this.setLoading(false));
  }

  setProfile(profileData: Profile) {
    this.profile = profileData;
  }

  async updateProfile(updatedField: Record<string, string>) {
    this.setLoading(true);

    const request = {
      email: this.profile.email,
      phone: this.profile.phone_number,
      // type: this.profile.status || 'unspecified',
      type: this.profile.status || UserStatus.NOT_ESTABLISHED,
      ...updatedField,
    };

    return await Api.updateProfile(request)
      .then(
        action('fetchSuccess', () => {
          return true;
        }),
        action('fetchError', (error: string) => {
          console.error(error);
          toast.error('Произошла ошибка');
        })
      )
      .finally(() => this.setLoading(false));
  }

  resetValues() {
    this.profile = initFields;
  }
}
