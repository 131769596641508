import client from '../../httpClient/clientV1';

export type Transport = {
  id: string;
  carClassId: string;
  grnz: string;
  model: string;
  name: string;
};

async function fetchTransport() {
  const response = await client.get<Transport[]>('/cars');
  return response.data;
}

export async function fetchTransportById(id: any) {
  const result = await client.get<any>(`/cars/${id}`);
  return result.data;
}

async function deleteTransport(id: string) {
  const response = await client.delete(`/cars/${id}`);
  return response.data;
}

export async function createTransport(params: any) {
  const result = await client.post<any>('/cars', params);
  return result.data;
}

export async function updateTransport(id: any, params: any) {
  const result = await client.put<any>(`/cars/${id}`, params);
  return result.data;
}

export default Object.assign({
  fetchTransport,
  fetchTransportById,
  deleteTransport,
  createTransport,
  updateTransport,
});
