import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Button from '../../components/Button';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InputText from '../../components/InputText';
// import Utils from '../../utils';

import rootStore from '../../store';
const { bookingProcessStore } = rootStore;

function SetProfileStep() {
  const { t } = useTranslation(['booking', 'common']);
  const isDisabled = bookingProcessStore.profile.phone_number === '' || bookingProcessStore.profile.email === '';

  return (
    <div>
      <Link to="#" className="link-back my-4" onClick={() => bookingProcessStore.prevStep()}>
        {t('back', { ns: 'common' })}
      </Link>
      <h3 className="mb-32px">{t('provide_contact_information_for_the_driver')}</h3>

      <div className="bg-white p-4 rounded shadow-main mb-40px">
        <InputText
          label={t('phone')}
          placeholder={t('enter_phone_number') || ''}
          value={bookingProcessStore.profile.phone_number}
          // onChange={(e) => bookingProcessStore.onChangeProfile('phone_number', Utils.phoneFormat(e.target.value))}
          onChange={(e) => bookingProcessStore.onChangeProfile('phone_number', e.target.value)}
          clear={() => bookingProcessStore.onChangeProfile('phone_number', '')}
          className="mb-4"
        />
        <InputText
          label={t('email')}
          placeholder={t('enter_email') || ''}
          value={bookingProcessStore.profile.email as string}
          onChange={(e) => bookingProcessStore.onChangeProfile('email', e.target.value)}
          clear={() => bookingProcessStore.onChangeProfile('email', '')}
          className="mb-4"
        />
        <Form.Check
          type="checkbox"
          id="default-checkbox3"
          label={t('matches_the_contact_details_of_the_profile')}
          // checked={store.profile.isProfileData as boolean}
          onChange={(e) => bookingProcessStore.onToggleContactsData(e.target.checked)}
        />
      </div>
      <div className="d-flex justify-content-center">
        <Button
          text={t('proceed', { ns: 'common' })}
          className="d-inline-block m-auto"
          onClick={() => bookingProcessStore.nextStep()}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
}

export default observer(SetProfileStep);
