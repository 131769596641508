import client from '../../httpClient/clientV1';
import { Booking } from '../Booking/api';

export type BookingResponse = {
  id: string;
  qrCode: string;
  number: string;
};

async function fetchSlots(body: any) {
  const response = await client.post<any>('/booking_slots', body);
  return response.data;
}

async function postBooking(body: any) {
  const response = await client.post<Booking>('/bookings', body);
  return response.data;
}

export default Object.assign({
  fetchSlots,
  postBooking,
});
