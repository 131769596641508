// import { observer } from 'mobx-react-lite';
import PhoneBlock from './components/PhoneBlock';
import EmailBlock from './components/EmailBlock';
// import PasswordBlock from './components/PasswordBlock';

function Account() {
  return (
    <div className="p-4 shadow-main rounded-4 custom-pointer bg-white d-grid gap-4 mt-2 mt-lg-0">
      <PhoneBlock />
      <EmailBlock />
      {/* <PasswordBlock /> */}
    </div>
  );
}

export default Account;
