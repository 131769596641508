import { Modal as BSModal, ModalProps as BSModalProps } from 'react-bootstrap';
import Icon from './../../components/Icon';
import { ReactNode } from 'react';
import classNames from 'classnames';

type ModalProps = BSModalProps & {
  header?: string;
  body?: ReactNode;
  footer?: ReactNode;
};

function Modal({ header, body, footer, contentClassName, ...props }: ModalProps) {
  if (props.centered === undefined) props.centered = true;
  if (props.scrollable === undefined) props.scrollable = true;

  return (
    <BSModal {...props}>
      <Icon name="close" width={22} height={22} className="btn-cross position-absolute" onClick={props.onHide} />
      {header && (
        <BSModal.Header>
          <BSModal.Title>{header}</BSModal.Title>
        </BSModal.Header>
      )}
      {body && <BSModal.Body className={classNames({ 'pb-4': footer })}>{body}</BSModal.Body>}
      {footer && <BSModal.Footer>{footer}</BSModal.Footer>}
    </BSModal>
  );
}

export default Object.assign(Modal, {
  Body: BSModal.Body,
  Header: BSModal.Header,
  Title: BSModal.Title,
  Footer: BSModal.Footer,
  Dialog: BSModal.Dialog,
  TRANSITION_DURATION: BSModal.TRANSITION_DURATION,
  BACKDROP_TRANSITION_DURATION: BSModal.BACKDROP_TRANSITION_DURATION,
});
