import client from '../../httpClient/clientV1';

export type MetaBaseConfig = {
  token: string;
  url: string;
};

async function fetchMetaBaseDashboard(id: string) {
  const response = await client.get<MetaBaseConfig>(`/analytic/cred/${id}`);
  return response.data;
}

export default Object.assign({
  fetchMetaBaseDashboard,
});
