import { useEffect, useRef } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import LanguageSwitcher from '../LanguageSwitcher';
import ProdileSwitcher from '../ProfileSwitcher';

import { observer } from 'mobx-react-lite';
import store from './../../store/';
import { ADMIN_ROLE, ANALYTIC_ROLE, CLIENT_ROLE } from '../../store/authStore';
import Icon from '../Icon';
import { useState } from 'react';
import AnalyticMenu from '../AnalyticMenu';
const { authStore, notificationStore } = store;

function Header() {
  const { t } = useTranslation('header');
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    function hideOnScroll() {
      setIsOpen(false);
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('scroll', hideOnScroll);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('scroll', hideOnScroll);
    };
  }, []);

  const { userRole } = authStore;

  const isAdmin = userRole === ADMIN_ROLE;
  const isAnalyst = userRole === ANALYTIC_ROLE;
  const isClient = userRole === CLIENT_ROLE;

  const getLink = () => {
    if (isAdmin) return '/dictionary';
    if (isAnalyst) return '/analytic-workload';

    return '/profile/account';
  };

  const DropdownBlock = ({ className }: { className?: string }) => (
    <div className={`d-flex align-items-center gap-3 ${className}`}>
      {isAnalyst && <AnalyticMenu />}
      <LanguageSwitcher />
      <ProdileSwitcher
        isClient={isClient}
        redirectLink={getLink()}
        notificationsCount={notificationStore.unredNotificationCount}
      />
      {isClient && (
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="p-0 border-0 text-primary"
          onClick={() => setIsOpen((isOpen) => !isOpen)}
        >
          <Icon name={isOpen ? 'close' : 'menu_points'} />
        </Navbar.Toggle>
      )}
    </div>
  );

  const CollapseBlock = () => (
    <Navbar.Collapse className="justify-content-end">
      <Nav className="d-flex justify-content-end gap-3 gap-xl-32px my-auto py-3 py-lg-0">
        <>
          {isAdmin && (
            <Nav.Link as={Link} to="/dictionary" eventKey="1" className="text-primary py-0">
              {t('directory')}
            </Nav.Link>
          )}
          {isClient && (
            <>
              {/* <Nav.Link as={Link} to="/ui-kit" className="text-primary py-0">
                UI
              </Nav.Link> */}
              <Nav.Link as={Link} to="/booking" eventKey="6" className="text-primary py-0">
                {t('booking')}
              </Nav.Link>
              <Nav.Link as={Link} to="/transport" eventKey="7" className="text-primary py-0">
                {t('transport')}
              </Nav.Link>

              <Nav.Link as={Link} to="/mapp" eventKey="8" className="text-primary py-0">
                {t('app')}
              </Nav.Link>
            </>
          )}
        </>
      </Nav>
    </Navbar.Collapse>
  );

  return (
    <Navbar
      ref={ref}
      fixed="top"
      expand="lg"
      expanded={isOpen}
      // collapseOnSelect
      // onToggle={() => setIsOpen((isOpen) => !isOpen)}
      onSelect={() => setIsOpen(false)}
      bg="white"
      className="w-100 shadow-head py-2 py-md-3"
    >
      <Container>
        <Navbar.Brand
          as={Link}
          to={getLink()}
          className="py-0 text-primary d-flex flex-grow-1"
          style={{ minHeight: 32 }}
          onClick={() => setIsOpen(false)}
        >
          <Icon name="logo" width={26} height={26} color="#053B75" className="d-sm-block d-xl-none me-2 text-primary" />
          <h5 className="d-xl-none my-auto text-darkBlue">{t('site')}</h5>
          <h5 className="d-none d-xl-block my-auto text-darkBlue">{t('title')}</h5>
        </Navbar.Brand>
        <DropdownBlock className="d-lg-none" />
        <CollapseBlock />
        <DropdownBlock className="d-none d-lg-flex ms-4" />
      </Container>
    </Navbar>
  );
}

export default observer(Header);
