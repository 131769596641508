import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Form, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// import rootStore from '../../../../store';
// import EmptyResult from '../../../../components/EmptyResult';
// const { mappStore } = rootStore;

import { dictionaries, Dictionary, dictionaryCategories, DictionaryCategory } from '../../api';
import Icon from '../../../../components/Icon';
import EmptyResult from '../../../../components/EmptyResult';

const Thead = () => (
  <div className="d-flex">
    <div style={{ width: '10%' }} className="flex-shrink-0 text-secondary fs-7">
      Иконка
    </div>
    <div style={{ width: '25%' }} className="flex-shrink-0 text-secondary fs-7">
      Наименование
    </div>
    <div style={{ width: '25%' }} className="flex-shrink-0 text-secondary fs-7">
      Подкатегории
    </div>
    <div style={{ width: '15%' }} className="flex-shrink-0 text-secondary fs-7">
      Добавлен
    </div>
    <div style={{ width: '15%' }} className="flex-shrink-0 text-secondary fs-7">
      Изменён
    </div>
    <div style={{ width: '10%' }} className="flex-shrink-0 text-center text-secondary fs-7">
      Действия
    </div>
  </div>
);

const TRow = ({ category }: { category: DictionaryCategory }) => (
  <div className="d-flex">
    <div style={{ width: '10%' }} className="flex-shrink-0">
      <Icon name={category.icon} />
    </div>
    <div style={{ width: '25%' }} className="flex-shrink-0">
      {category.name}
    </div>
    <div style={{ width: '25%' }} className="flex-shrink-0">
      {category.subcategory.join(', ')}
    </div>
    <div style={{ width: '15%' }} className="flex-shrink-0">
      {/* {category.created} */} -
    </div>
    <div style={{ width: '15%' }} className="flex-shrink-0">
      {/* {category.updated} */} -
    </div>
    <div style={{ width: '10%' }} className="flex-shrink-0 d-flex justify-content-between align-items-center px-12px">
      <Link to={`/dictionary/category/${category.id}`} style={{ color: '#0B1F33' }}>
        <Icon name="edit" className="cursor-pointer" />
      </Link>
      <Icon name="trash" style={{ position: 'relative', top: 2, left: 6, opacity: 0.3 }} />
    </div>
  </div>
);

function DictionaryCategoryList() {
  const { t } = useTranslation(['transport', 'common']);

  const [searchQuery, setSearchQuery] = useState('');

  const filteredCategories = dictionaryCategories.filter((category: DictionaryCategory) =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const hasCategory = !!filteredCategories?.length;

  return (
    <Container className="flex-grow-1 pb-3 pb-sm-5">
      <Row>
        <Col md={9}>
          <Link to="/dictionary" className="link-back my-4">
            {t('back', { ns: 'common' })}
          </Link>
          <h2 className="mt-40px mb-4">Категории ТС</h2>

          <div className="bg-white rounded shadow-main" style={{ marginBottom: '4rem' }}>
            {/* <div className="d-flex p-4" style={{ borderBottom: `${dictionaries?.length ? 0 : '1px'} solid #E1E1E1` }}> */}
            <div className="d-flex p-4" style={{ borderBottom: '1px solid #E1E1E1' }}>
              <Form.Control
                type="search"
                placeholder="Поиск"
                className="flex-grow-1"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            {hasCategory && (
              <div className="d-flex flex-column gap-4 p-4">
                <Thead />
                {filteredCategories.map((category, index) => (
                  <div key={index}>
                    <TRow category={category} />
                  </div>
                ))}
              </div>
            )}

            {!hasCategory && <EmptyResult text="Нет категорий по запросу" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default observer(DictionaryCategoryList);
