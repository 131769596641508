import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Booking } from '../../../../api';
import Utils from './../../../../../../utils';

function BookingCard({ booking }: { booking: Booking }) {
  const navigate = useNavigate();
  const { t } = useTranslation(['booking']);

  const { carClassId } = booking.car.carClass;

  const bookingDate = Utils.getDate(booking.timestamp, 'd MMMM yyyy') + ' ' + t('year');
  const slot = Utils.getSlot(booking.timestamp);
  const statusIcon = Utils.getStatus(booking?.status)?.icon;
  // const statusIcon = Utils.getStatus(booking.status).icon;
  const transportIcon = Utils.getTransportIconByClass(carClassId);

  return (
    <div
      className="d-flex flex-column flex-md-row p-4 rounded custom-pointer bg-white shadow-main mb-3 mb-md-4 cursor-pointer"
      onClick={() => navigate(`/booking/${booking.id}`)}
    >
      <div className="col d-flex align-items-center gap-3">
        {statusIcon}
        <div className="d-grid mb-3 mb-md-0">
          <span>{bookingDate}</span>
          <span>{slot}</span>
        </div>
      </div>
      <div className="col d-flex gap-3 mb-3 mb-md-0">
        {transportIcon}
        <div className="d-grid">
          <span>
            {booking.car?.name} {booking.car?.model}
          </span>
          <span className="text-secondary">{booking.car.grnz}</span>
        </div>
      </div>
      <div className="col">
        <span className="ms-0 ms-md-3">{booking.mapp.name}</span>
      </div>
    </div>
  );
}

export default BookingCard;
