export type Dictionary = {
  title: string;
  link: string;
  created: string;
  updated: string;
};

export const dictionaries: Dictionary[] = [
  {
    title: 'Категории ТС',
    link: '/dictionary/category',
    created: '21.11.2022',
    updated: '21.11.2022',
  },
  {
    title: 'Справочник АПП',
    link: '/dictionary/app',
    created: '21.11.2022',
    updated: '21.11.2022',
  },
];

export type DictionaryCategory = {
  id: string;
  icon: string;
  name: string;
  subcategory: string[];
  created: string;
  updated: string;
};

export const dictionaryCategories: DictionaryCategory[] = [
  {
    id: '1',
    icon: 'bike',
    name: 'Мотоциклы',
    subcategory: ['A'],
    created: '10.09.2022',
    updated: '22.11.2022',
  },
  {
    id: '2',
    icon: 'legkovie',
    name: 'Легковые ТС',
    subcategory: ['B', 'BE'],
    created: '10.09.2022',
    updated: '22.11.2022',
  },
  {
    id: '3',
    icon: 'gruzovie',
    name: 'Грузовые ТС',
    subcategory: ['C', 'C1', 'C1E'],
    created: '10.09.2022',
    updated: '22.11.2022',
  },
  {
    id: '4',
    icon: 'bus',
    name: 'Автобусы',
    subcategory: ['D', 'D1', 'D1E'],
    created: '10.09.2022',
    updated: '22.11.2022',
  },
];
