import classNames from 'classnames';

type Props = React.HTMLAttributes<HTMLElement> & {
  count: number;
};

const NotificationCount = ({ count, className, ...props }: Props) => {
  if (count > 999) return null;

  return (
    <div
      className={classNames(`notification-count ${className}`, {
        'notification-count--middle': count > 9,
        'notification-count--big': count > 99,
      })}
      {...props}
    >
      {count}
    </div>
  );
};

export default NotificationCount;
