import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { observer } from 'mobx-react-lite';
import { Container, Row, Col } from 'react-bootstrap';
import Loader from '../../../../components/Loader';
import ConfirmModal from './components/ConfirmModal';
import SuccessModal from './components/SuccessModal';
import Utils from './../../../../utils';

import store from '../../../../store';
const { bookingStore } = store;

function BookingSingle() {
  const { id } = useParams();
  const { t } = useTranslation(['booking', 'status']);

  const navigate = useNavigate();

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  useEffect(() => {
    if (!id) return;

    bookingStore.fetchBookingById(id);
  }, [id]);

  const date = Utils.getDate(bookingStore.booking?.timestamp, 'd MMMM yyyy');
  const slot = Utils.getSlot(bookingStore.booking?.timestamp);

  const pageTitle = `${t('booking')} ${date} ${t('year_short')}., ${slot}`;
  const confirmModalTitle = `${t('confirm_cancellation_booking')} ${date} ${t('year_short')}., ${slot}`;

  const isLoadBooking = !bookingStore.isLoading && !!bookingStore.booking;

  const isCancelBlockVisible = () => {
    if (bookingStore.booking === null) return false;

    const { status } = bookingStore.booking;
    return status === '10' || status === '20' || status === '35' || status === '90';
  };

  const qrCode = JSON.stringify(
    {
      qr_hash: bookingStore.booking?.qrCode,
      // booking_id: bookingStore.booking?.id,
      // car_grnz: bookingStore.booking?.car.grnz,
      // booking_time: bookingStore.booking?.timestamp,
      // cancel_code: bookingStore.booking?.number,
    },
    null,
    2
  );

  return (
    <div className="flex-grow-1 pb-3 pb-sm-5">
      <Container>
        <Row>
          <Col>
            <Button variant="link" onClick={() => navigate('/booking')} className="link-back text-primary p-0 my-4">
              {t('back', { ns: 'common' })}
            </Button>
          </Col>
        </Row>
        <Row>
          {bookingStore.isLoading && (
            <Col>
              <div className="bg-white rounded shadow-main p-4">
                <Loader />
              </div>
            </Col>
          )}

          {isLoadBooking && (
            <>
              <Col xs={12} lg={9}>
                <div className="d-grid shadow-main rounded-3 bg-white">
                  <div className="p-4">
                    <h4 className="m-0">{pageTitle}</h4>
                  </div>
                  <hr className="m-0" />

                  <div className="p-4 d-grid gap-4">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex gap-2">
                        {Utils.getStatus(bookingStore.booking?.status)?.icon}
                        <span>{t(Utils.getStatus(bookingStore.booking?.status).text, { ns: 'status' })}</span>
                      </div>
                    </div>
                    <div>
                      <h5>{t('vehicle_data')}</h5>
                      <div className="d-grid gap-4 mt-3">
                        <div className="d-grid">
                          <span className="text-caption">{t('category')} </span>
                          {bookingStore.booking?.car.carClass.name}
                        </div>
                        <div className="d-grid">
                          <span className="text-caption">{t('plate_number')} </span>
                          {bookingStore.booking?.car.grnz}
                        </div>
                      </div>
                    </div>

                    <hr className="m-0" />

                    <div>
                      <h5>{t('app_data')}</h5>
                      <div className="d-grid gap-4 mt-3">
                        <div className="d-grid">
                          <span className="text-caption">{t('name')}</span>
                          {bookingStore.booking?.mapp.name}
                        </div>
                        <div className="d-grid">
                          <span className="text-caption">{t('address')}</span>
                          {bookingStore.booking?.mapp.address}
                        </div>
                      </div>
                    </div>
                    <>
                      <hr className="m-0" />

                      <div>
                        <h5>{t('contacts')}</h5>
                        <div className="d-flex gap-4 mt-3">
                          <div className="d-grid">
                            <span className="text-caption">{t('phone')} </span>
                            {bookingStore.booking?.driver.phone || t('no_data', { ns: 'common' })}
                          </div>
                          <div className="d-grid">
                            <span className="text-caption">{t('email')} </span>
                            {bookingStore.booking?.driver.email || t('no_data', { ns: 'common' })}
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </Col>
              {isCancelBlockVisible() && (
                <Col xs={12} lg={3}>
                  <div className="d-grid shadow-main rounded-3 bg-white mt-3 mt-lg-0">
                    <div className="px-3 py-4">
                      <div className="pb-2 text-caption">{t('directions_app')}</div>
                      <QRCode
                        size={256}
                        style={{ width: 150, height: 150 }}
                        value={qrCode}
                        viewBox={`0 0 256 256`}
                        className="mt-2"
                      />
                      <div className="d-grid mt-3">
                        <div className="text-caption">{t('cancel_code')}</div>
                        <h5>{bookingStore.booking?.number}</h5>
                      </div>
                    </div>
                    <hr className="m-0" />
                    <Link to="#" className="px-3 p-4" onClick={() => setIsConfirmModalVisible(true)}>
                      {t('cancel_booking')}
                    </Link>
                  </div>
                </Col>
              )}
            </>
          )}
        </Row>
      </Container>

      <ConfirmModal
        title={confirmModalTitle}
        show={isConfirmModalVisible}
        onClose={() => setIsConfirmModalVisible(false)}
        onSuccess={() => {
          setIsSuccessModalVisible(true);
          id && bookingStore.fetchBookingById(id);
        }}
        booking={bookingStore.booking}
      />
      <SuccessModal show={isSuccessModalVisible} onClose={() => setIsSuccessModalVisible(false)} />
    </div>
  );
}

export default observer(BookingSingle);
