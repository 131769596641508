import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Icon from '../../../../components/Icon';
import Select from '../../../../components/Select';
import Modal from './../../../../components/Modal';
import Button from './../../../../components/Button';
import { Transport } from './../../api';

import EmptyResult from '../../../../components/EmptyResult';
import Loader from '../../../../components/Loader';

import rootStore from '../../../../store';
import { SelectOption } from '../../../../models';
import { transportClassOptions } from '../../../../models/static';
const { transportStore } = rootStore;

export enum VehicleCategory {
  // LEGKOVIE = 'legkovie',
  // GRUZOVIE = 'gruzovie',
  // BUS = 'bus',
  // BIKE = 'bike',
  LEGKOVIE = '1',
  GRUZOVIE = '2',
  BUS = '3',
  BIKE = '4',
}

const setCategoryIcon = (carClassId: string) => {
  switch (carClassId) {
    case '1':
      return 'legkovie';
    case '2':
      return 'gruzovie';
    case '3':
      return 'bus';
    case '4':
      return 'bike';
    default:
      return '';
  }
};

const fullOptions = [{ label: 'all', value: '' }, ...transportClassOptions];

function TransportList() {
  const { t } = useTranslation(['transport', 'header', 'common']);
  const navigation = useNavigate();

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);
  const [selectedTransport, setSelectedTransport] = useState<Transport>();

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(fullOptions[0]);

  useEffect(() => {
    transportStore.fetchTransport();
  }, []);

  const openConfirmModal = (selectedTransport: Transport) => {
    setSelectedTransport(selectedTransport);
    setIsOpenConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setSelectedTransport(undefined);
    setIsOpenConfirmModal(false);
  };

  const deleteVehicleHandler = async () => {
    if (!selectedTransport) return;

    const successDelete = await transportStore.deleteTransportById(selectedTransport?.id);

    if (successDelete) {
      transportStore.fetchTransport();
      setIsOpenConfirmModal(false);
      setIsOpenSuccessModal(true);
    }
  };

  const deleteTransportLabel = () => {
    if (!selectedTransport) return '';

    const { name, model, grnz } = selectedTransport;

    return `${t('delete', { ns: 'common' })} ${name} ${model} ${grnz}?`;
  };

  const TableRow = ({ transport }: { transport: Transport }) => {
    return (
      <Row>
        <Col xs={12} lg={4}>
          <Icon name={setCategoryIcon(transport.carClassId)} />
          <span className="ms-3">{transport.name}</span>
        </Col>
        <Col xs={12} lg={8}>
          <div className="d-flex justify-content-between align-items-center">
            <span>{transport.grnz}</span>
            <div>
              <Link to={`/transport/${transport?.id}`}>
                <Icon name="edit" className="transport-icon" />
              </Link>
              <Icon
                name="trash"
                className="transport-icon ms-4 position-relative"
                style={{ top: 2 }}
                onClick={() => openConfirmModal(transport)}
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const filteredTransport = transportStore.transportList
    .filter(
      (transport) =>
        transport.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        transport.grnz.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((transport) => selectedOption?.value === '' || transport.carClassId === selectedOption?.value);

  const isLoadResult = !transportStore.isLoading && !!filteredTransport?.length;
  const isLoadEmptyResult = !transportStore.isLoading && !filteredTransport?.length;

  return (
    <>
      <Container className="flex-grow-1 pb-3 pb-sm-5">
        <Row>
          <Col md={9}>
            <h2 className="mt-40px mb-4">{t('transport', { ns: 'header' })}</h2>

            <div
              className="d-none d-sm-flex justify-content-between rounded py-28px px-4 mb-4"
              style={{ border: '1px dashed rgba(29, 93, 235, 0.18)' }}
            >
              <span className="text-secondary">{t('add_new_vehicle')}</span>

              <Link to="/transport/add" className="link-primary fs-6">
                {t('add', { ns: 'common' })}
              </Link>
            </div>

            <Button
              text={t('add', { ns: 'common' })}
              className="d-flex justify-content-center d-sm-none mb-3 w-100"
              onClick={() => navigation('/transport/add')}
            />

            <div className="bg-white rounded shadow-main">
              <div
                className="p-3 p-md-4"
                style={{ borderBottom: `${filteredTransport?.length ? 0 : '1px'} solid #E1E1E1` }}
              >
                <Row>
                  <Col xs={{ span: 12, order: 2 }} md={{ span: 4, order: 1 }}>
                    <Select
                      options={fullOptions}
                      value={selectedOption}
                      formatOptionLabel={({ value, label }) => <>{t(label)}</>}
                      onChange={(e) => setSelectedOption(e)}
                    />
                  </Col>
                  <Col xs={{ span: 12, order: 1 }} md={{ span: 8, order: 2 }} className="mb-3 mb-md-0">
                    <Form.Control
                      type="search"
                      placeholder={t('search', { ns: 'common' }) || ''}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>

              {transportStore.isLoading && <Loader />}

              {isLoadResult && (
                <>
                  {filteredTransport?.map((transport: Transport, index: number) => (
                    <div key={index} className="p-3 p-md-4 border-top" style={{ borderColor: '#E1E1E1' }}>
                      <TableRow transport={transport} />
                    </div>
                  ))}
                </>
              )}

              {isLoadEmptyResult && <EmptyResult text={t('vehicles_were_not_found', { ns: 'transport' })} />}
            </div>
          </Col>
        </Row>
      </Container>

      <Modal
        show={isOpenConfirmModal}
        onHide={closeConfirmModal}
        header={t('removing_the_vehicle') || ''}
        body={deleteTransportLabel()}
        footer={
          <div className="d-flex flex-column flex-sm-row gap-4 w-100">
            <Button
              variant="primary"
              onClick={() => deleteVehicleHandler()}
              text={t('delete', { ns: 'common' })}
              // className="me-4"
              isLoading={transportStore.isLoading}
            />
            <Button variant="outline" onClick={closeConfirmModal} text={t('cancellation', { ns: 'common' })} />
          </div>
        }
      />

      <Modal
        show={isOpenSuccessModal}
        onHide={() => setIsOpenSuccessModal(false)}
        body={
          <div className="w-100 d-flex flex-column align-items-center justify-content-center">
            <Icon name="accept" width={80} height={80} style={{ color: '#C9D8FA' }} className="mt-44px mb-4" />
            <h4 className="m-0 text-center">{t('the_vehicle_is_removed')}</h4>
          </div>
        }
        footer={
          <div className="w-100 d-flex justify-content-center">
            <Button
              variant="primary"
              onClick={() => setIsOpenSuccessModal(false)}
              text={t('to_the_list_of_transport')}
            />
          </div>
        }
      />
    </>
  );
}

export default observer(TransportList);
