import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Form, Col, Container, Row } from 'react-bootstrap';

// import rootStore from '../../../../store';
// import EmptyResult from '../../../../components/EmptyResult';
// const { mappStore } = rootStore;

import { dictionaries, Dictionary } from './../../api';
import EmptyResult from '../../../../components/EmptyResult';

const Thead = () => (
  <div className="d-flex">
    <div className="flex-grow-1 flex-shrink-0 text-secondary fs-7">Наименование</div>
    <div style={{ width: '10%' }} className="flex-shrink-0 text-secondary fs-7">
      Добавлен
    </div>
    <div style={{ width: '10%' }} className="flex-shrink-0 text-secondary fs-7 ms-4">
      Изменен
    </div>
  </div>
);

const TRow = ({ dictionary }: { dictionary: Dictionary }) => (
  <div className="d-flex">
    <div className="flex-grow-1  flex-shrink-0 text-primary">
      <Link to={dictionary.link}>{dictionary.title}</Link>
    </div>
    <div style={{ width: '10%' }} className="flex-shrink-0">
      {/* {dictionary.created} */} -
    </div>
    <div style={{ width: '10%' }} className="flex-shrink-0  ms-4">
      {/* {dictionary.updated} */} -
    </div>
  </div>
);

function DictionaryList() {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredDictionaries = dictionaries.filter((dictionary: Dictionary) =>
    dictionary.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const hasDictionary = !!filteredDictionaries?.length;

  return (
    <Container className="flex-grow-1 pb-3 pb-sm-5">
      <Row>
        <Col md={9}>
          <h2 className="mt-40px mb-4">Справочники</h2>

          <div className="bg-white rounded shadow-main" style={{ marginBottom: '4rem' }}>
            {/* <div className="d-flex p-4" style={{ borderBottom: `${dictionaries?.length ? 0 : '1px'} solid #E1E1E1` }}> */}
            <div className="d-flex p-4" style={{ borderBottom: '1px solid #E1E1E1' }}>
              <Form.Control
                type="search"
                placeholder="Поиск"
                className="flex-grow-1"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            {hasDictionary && (
              <div className="d-flex flex-column gap-4 p-4">
                <Thead />
                {filteredDictionaries.map((dictionary, index) => (
                  <div key={index}>
                    <TRow dictionary={dictionary} />
                  </div>
                ))}
              </div>
            )}

            {!hasDictionary && <EmptyResult text="Нет справочников по запросу" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default observer(DictionaryList);
