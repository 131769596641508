import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import InputText from '../../../../components/InputText';
import Spinner from '../../../../components/Spinner';

import store from '../../../../store';
import { Row, Col } from 'react-bootstrap';
const { profileStore } = store;

function PhoneBlock() {
  const { t } = useTranslation(['profile', 'common']);
  const [newPhone, setNewPhone] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (isEdit) setNewPhone('');
  }, [isEdit]);

  const onUpdateHandler = async () => {
    const isSuccessResponse = await profileStore.updateProfile({ phone: newPhone });

    if (isSuccessResponse) {
      profileStore.fetchProfile();
      toast.info(t('data_changed', { ns: 'common' }));
      setNewPhone('');
      setIsEdit(false);
    }
  };

  const onInputChangeHandler = (e: any) => {
    const number = e.target.value.replace('+', '').replace(/\D/, '');
    setNewPhone(`+${number}`);
  };

  const Toggle = observer(() => (
    <div>
      <p className="mb-1 text-caption fs-7">{t('phone')}</p>
      <div className="d-flex">
        {
          <h5 className="my-auto me-4">
            {profileStore.isLoading && <Spinner size={24} className="m-auto text-primary" />}
            {!profileStore.isLoading && profileStore.profile.phone_number}
          </h5>
        }
        <Link to="#" className="link-primary fs-6" onClick={() => setIsEdit(true)}>
          {t('change')}
        </Link>
      </div>
    </div>
  ));

  if (!isEdit) return <Toggle />;

  return (
    <div className="py-32px px-4 rounded-1 d-grid gap-32px" style={{ border: '1px dashed rgba(29, 93, 235, 0.18)' }}>
      <h5 className="m-0">
        {t('number_change')} {profileStore.profile.phone_number}
      </h5>

      <Row>
        <Col md={6} sx={12}>
          <InputText
            label={t('new_phone_number')}
            placeholder={t('enter_phone_number') || ''}
            value={newPhone}
            onChange={onInputChangeHandler}
            disabled={profileStore.isLoading}
          />
        </Col>
      </Row>

      <div className="d-flex flex-column flex-sm-row gap-4">
        <Button
          variant="primary"
          text={t('save', { ns: 'common' })}
          onClick={() => onUpdateHandler()}
          isLoading={profileStore.isLoading}
          disabled={!newPhone}
        />
        <Button
          variant="outline"
          text={t('cancel', { ns: 'common' })}
          onClick={() => setIsEdit(false)}
          disabled={profileStore.isLoading}
        />
      </div>
    </div>
  );
}

export default observer(PhoneBlock);
