import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import Icon from '../../../../../components/Icon';
import Modal from '../../../../../components/Modal';

type Props = {
  show: boolean;
  onClose: () => void;
};

function SuccessdModal({ show, onClose }: Props) {
  const { t } = useTranslation('booking');
  const navigate = useNavigate();

  return (
    <Modal
      show={show}
      onHide={() => onClose()}
      body={
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          <Icon name="accept" width={80} height={80} style={{ color: '#C9D8FA' }} className="mt-44px mb-4" />
          <h4 className="m-0 text-center">{t('reservations_canceled')}</h4>
        </div>
      }
      footer={
        <div className="w-100 d-flex justify-content-center">
          <Button variant="primary" onClick={() => navigate('/booking')} text={t('home')} />
        </div>
      }
    />
  );
}

export default SuccessdModal;
