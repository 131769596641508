import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import Button from '../../../../components/Button';
import InputText from '../../../../components/InputText';
import Spinner from '../../../../components/Spinner';

import store from '../../../../store';
import { toast } from 'react-toastify';
const { profileStore } = store;

function EmailBlock() {
  const [newEmail, setNewEmail] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (isEdit) setNewEmail('');
  }, [isEdit]);

  const onUpdateHandler = async () => {
    const isSuccessResponse = await profileStore.updateProfile({ email: newEmail });

    if (isSuccessResponse) {
      profileStore.fetchProfile();
      toast.info('Данные были изменены');
      setNewEmail('');
      setIsEdit(false);
    }
  };

  const Toggle = observer(() => (
    <div>
      <p className="mb-1 text-caption fs-7">Email</p>
      <div className="d-flex">
        {
          <h5 className="my-auto me-4">
            {profileStore.isLoading && <Spinner size={24} className="m-auto text-primary" />}
            {!profileStore.isLoading && profileStore.profile.email}
          </h5>
        }
        {/* <Link to="#" className="link-primary fs-6" onClick={() => setIsEdit(true)}>
          Изменить
        </Link> */}
      </div>
    </div>
  ));

  if (!isEdit) return <Toggle />;

  return (
    <div className="py-32px px-4 rounded-1 d-grid gap-32px" style={{ border: '1px dashed rgba(29, 93, 235, 0.18)' }}>
      <h5 className="m-0">Изменение электронной почты {profileStore.profile.email}</h5>

      <div className="d-flex align-items-center">
        <InputText
          label="Укажите электронную почту"
          placeholder="Укажите электронную почту"
          className="w-50 me-32px"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          disabled={profileStore.isLoading}
        />
      </div>
      <div>
        <Button
          variant="primary"
          text="Сохранить"
          className="me-4"
          onClick={onUpdateHandler}
          isLoading={profileStore.isLoading}
          disabled={!newEmail}
        />
        <Button variant="outline" text="Отменить" onClick={() => setIsEdit(false)} />
      </div>
    </div>
  );
}

export default observer(EmailBlock);
