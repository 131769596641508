import { useTranslation } from 'react-i18next';

import Icon from '../Icon';

function Footer() {
  const { t } = useTranslation('footer');
  return (
    <footer className="fs-7 text-center p-3 w-100 text-muted bg-light">
      <div className="container d-flex flex-wrap flex-xl-nowrap justify-content-center justify-content-xl-between align-items-center">
        <div className="mb-3 mb-xl-0 lh-base">© {t('title')}, 2022</div>
        <div className="d-flex flex-column align-items-center flex-lg-row gap-3 gap-xl-3">
          <div className="lh-1">{t('support')}</div>
          <div className="d-flex justify-content-center justify-content-xl-start align-items-center">
            <Icon name="call" />
            <a href="tel:+79299018301" className="ms-2 text-muted lh-base">
              +7 (929) 901-83-01
            </a>
          </div>
          <div className="d-flex justify-content-center justify-content-xl-start align-items-center">
            <Icon name="mail" />
            <a href="mailto:HelpDesk_KT@telematika.com" className="ms-2 text-muted lh-base">
              HelpDesk_KT@telematika.com
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
