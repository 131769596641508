import { makeAutoObservable } from 'mobx';
import { RootStore } from '.';
import jwt_decode from 'jwt-decode';

// export const ADMIN_ROLE = 'admin-pfu';
// export const ANALYST_ROLE = 'analyst-pfu';
// export const CLIENT_ROLE = 'client';

export const ADMIN_ROLE = 'administrator';
export const ANALYTIC_ROLE = 'analytic';
export const CLIENT_ROLE = 'users';

export class AuthStore {
  rootStore: RootStore;

  account: any = undefined;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setAccount(token: string) {
    const rawAccount: any = jwt_decode(token);

    // console.log('rawAccount >>> ', rawAccount);

    // for roles test
    // rawAccount.scope = ADMIN_ROLE;
    // rawAccount.scope = ANALYTIC_ROLE;
    // rawAccount.scope = '';

    // for roles test
    // rawAccount.role = ADMIN_ROLE;
    // rawAccount.role = ANALYTIC_ROLE;
    // rawAccount.role = '';

    this.account = rawAccount;
  }

  get userRole() {
    if (this.account === undefined) return false;

    // const isAdmin = this.account?.scope?.includes(ADMIN_ROLE);
    // const isAnalyst = this.account?.scope?.includes(ANALYST_ROLE);

    const isAdmin = this.account?.role === ADMIN_ROLE;
    const isAnalyst = this.account?.role === ANALYTIC_ROLE;

    if (isAdmin) {
      return ADMIN_ROLE;
    } else if (isAnalyst) {
      return ANALYTIC_ROLE;
    } else {
      return CLIENT_ROLE;
    }
  }

  // get isAnalyst() {
  //   if (this.account === undefined) return false;

  //   return this.account?.scope?.includes(ANALYST_ROLE);
  // }

  // get isClient() {
  //   if (this.account === undefined) return false;

  //   return !this.isAdmin && !this.isAnalyst;
  // }
}

// const authStore = new AuthStore();
// export default authStore;
