import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { addYears, getMonth, getYear } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import { periods, months } from '../../mock';
import Utils from './../../utils';

import rootStore from '../../store';
const { bookingProcessStore } = rootStore;

function SelectDateStep() {
  const { t } = useTranslation(['booking', 'common']);
  const years = [getYear(new Date()), getYear(addYears(new Date(), 1))];

  useEffect(() => {
    bookingProcessStore.getSlots();
  }, []);

  const onChangeDateHandler = (date: Date | null) => {
    bookingProcessStore.setSelectedHour(null);
    console.log('date >>> ', date);

    // if (date === null) return;
    // const _date = new Date(date);
    // // add a day
    // _date.setDate(_date.getDate() + 1);

    bookingProcessStore.setSelectedDay(date);
  };

  const onSelectPeriodHandler = (hour: number) => {
    bookingProcessStore.setSelectedHour(hour);
  };

  // TODO: delete +1 hack
  const selectedDate = `${Utils.getDate(bookingProcessStore.selectedDay, 'd MMMM', true)}, ${
    bookingProcessStore.selectedHoursPeriod
  }, ${Utils.getDate(bookingProcessStore.selectedDay, 'EEEE', true)}`;

  const isHasSlots = !bookingProcessStore.isLoading && bookingProcessStore?.slots;
  const isSelectHour = bookingProcessStore.selectedHour !== null;

  const lang = localStorage.getItem('lang');

  return (
    <div className="mb-5">
      <Link to="#" className="link-back my-4" onClick={() => bookingProcessStore.prevStep()}>
        {t('back', { ns: 'common' })}
      </Link>
      <h3 className="mb-4">{t('select_a_date_and_time')}</h3>
      <span>{t('you_book_interval_in_time_zone_located')}</span>
      <div className="shadow-main rounded-3 p-4 bg-white mt-4">
        <div className="calendar-container">
          {bookingProcessStore.isLoading && <Loader />}

          {isHasSlots && (
            <DatePicker
              inline
              locale={lang || 'ru'}
              selected={bookingProcessStore.selectedDay}
              includeDates={bookingProcessStore.includeDates}
              onChange={(date) => onChangeDateHandler(date)}
              renderCustomHeader={({ date, changeYear, changeMonth }) => (
                <div className="mb-1 d-flex justify-content-center">
                  <Dropdown align="end">
                    <Dropdown.Toggle className="bg-transparent border-0 d-flex gap-2 p-2 lh-base text-primary">
                      {months[getMonth(date)]}
                      <Icon name="small_down" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {months.map((option, index) => (
                        <Dropdown.Item key={option} onClick={() => changeMonth(Number(index))}>
                          {option}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown align="end">
                    <Dropdown.Toggle className="bg-transparent border-0 d-flex gap-2 p-2 lh-base text-primary">
                      {getYear(date)}
                      <Icon name="small_down" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {years.map((option) => (
                        <Dropdown.Item key={option} onClick={() => changeYear(Number(option))}>
                          {option}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            />
          )}
        </div>

        {bookingProcessStore.selectedDay && (
          <>
            <hr className="my-4 bg-divider" />
            <Form.Group className="mb-3 position-relative time-block">
              <div className="slots d-grid gap-3">
                {periods.map((period, index) => (
                  <div
                    key={index}
                    className={classNames('time-block-item py-2 px-3 text-center rounded-1', {
                      available: bookingProcessStore?.periodsByDay?.includes(index),
                      selected: bookingProcessStore?.selectedHour === index,
                    })}
                    onClick={() => onSelectPeriodHandler(index)}
                  >
                    {period}
                  </div>
                ))}
              </div>
            </Form.Group>
          </>
        )}
      </div>
      {isSelectHour && (
        <h6 className="pt-40px text-center">
          {t('you_have_selected')} <span className="fw-bold">{selectedDate}</span>
        </h6>
      )}

      <Button
        text={t('book')}
        className="d-block mx-auto mt-40px"
        disabled={!isSelectHour}
        onClick={() => bookingProcessStore.nextStep()}
      />
    </div>
  );
}

export default observer(SelectDateStep);
