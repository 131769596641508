import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Loader from '../../../../components/Loader';
import metaBaseStore from '../../store';

function MetabaseDashboard() {
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;

    metaBaseStore.fetchMetaBaseDashboard(id);

    return () => metaBaseStore.setConfig();
  }, [id]);

  if (metaBaseStore.isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center flex-grow-1">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex-grow-1">
      <iframe
        title="Metabase"
        className="w-100 flex-grow-1"
        style={{ height: 'calc( 100vh - 7.875rem )' }}
        src={metaBaseStore.dashboardUrl}
      />
    </div>
  );
}

export default observer(MetabaseDashboard);
