import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import rootStore from '../../store';
import Utils from './../../utils';

const { bookingProcessStore } = rootStore;

const TIME_LIMIT = 20;

function ConfirmStep() {
  const { t } = useTranslation(['booking', 'common']);
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(TIME_LIMIT);

  useEffect(() => {
    if (timeLeft === 0) {
      // navigate('/booking-list');
      bookingProcessStore.prevStep();
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [navigate, timeLeft]);

  const selectedDate = Utils.getDate(bookingProcessStore.selectedDay, 'd MMMM yyyy', true);

  return (
    <div className="mb-5">
      <Link to="#" className="link-back my-4" onClick={() => bookingProcessStore.prevStep()}>
        {t('back', { ns: 'common' })}
      </Link>
      <h3 className="mb-4">{t('confirm_application')}</h3>
      <div className="shadow-main rounded-3 p-4 bg-white mt-4 d-grid gap-4">
        <div className="d-grid gap-3">
          <div>
            <span className="text-caption">{t('vehicle')} </span>
            <br />
            {bookingProcessStore.selectedTransportLabel}
          </div>
          <div>
            <span className="text-caption">{t('app')} </span>
            <br />
            {bookingProcessStore.mappOption?.label}
          </div>
          <div>
            <span className="text-caption">{t('date')} </span> <br />
            {selectedDate}
          </div>
          <div>
            <span className="text-caption">{t('time')} </span> <br />
            {bookingProcessStore.selectedHoursPeriod}
          </div>
          <hr className="m-0" />
        </div>
        <div className="text-center">
          <span className="text-caption">
            {t('confirmation_time_limited')} <br /> {t('you_return_previous_step')}
          </span>
          <h6 className="mt-12px mb-4">{t('remaining')}</h6>
          <div className="d-flex justify-content-center align-items-center">
            <hr className="w-25 border-3" style={{ borderWidth: '2px', borderColor: '#0D4CD3', opacity: 1 }} />
            <h2 className="mb-0 mx-12px text-primary">00:00:{timeLeft > 9 ? timeLeft : '0' + timeLeft}</h2>
            <hr className="w-25 border-3" style={{ borderWidth: '2px', borderColor: '#0D4CD3', opacity: 1 }} />
          </div>
        </div>
      </div>

      <Button
        text={t('confirm', { ns: 'common' })}
        className="d-block mx-auto mt-5"
        onClick={() => bookingProcessStore.postBooking()}
        isLoading={bookingProcessStore.isLoading}
      />
    </div>
  );
}

export default ConfirmStep;
