import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Notification } from './../../api';
import EmptyResult from '../../../../components/EmptyResult';

import DropdownList from './../../../../components/DropdownList';

import store from './../../../../store/index';
import Loader from '../../../../components/Loader';
import { SelectOption } from '../../../../models';
import NotificationCount from '../../../../components/NotificationCount';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Utils from './../../../../utils';
import { NOTIFICATION_CATEGORIES } from '../../../../models/static';
import Icon from '../../../../components/Icon';
const { notificationStore } = store;

function NotificationList() {
  const { t } = useTranslation(['notification']);

  const categories = NOTIFICATION_CATEGORIES.map((category) => ({ ...category, label: t(category.label) }));

  useEffect(() => {
    // notificationStore.fetchNotifications();

    return () => notificationStore.setNotifications();
  }, []);

  const onReadAllHandler = async () => {
    if (!notificationStore.unredNotificationCount) return;
    if (notificationStore.notifications === undefined) return;

    const ids = notificationStore.notifications.map((notification) => notification.id);
    const isSuccessRead = await notificationStore.readNotifications(ids);

    if (isSuccessRead) {
      notificationStore.fetchNotifications().then(() => {
        toast.info('allRead');
      });
    }
  };

  const TableRow = ({ notification }: { notification: Notification }) => {
    return (
      <Link
        to={`/notification/${notification.id}`}
        className="d-flex p-4 border-top"
        style={{ borderColor: '#E1E1E1' }}
      >
        <div className="d-flex align-items-center me-4 flex-shrink-0">
          <span className="text-primary">
            {/* <Icon name={Utils.getNotificationIconById(notification.section)} /> */}
            <Icon name={Utils.getNotificationIconById('1')} />
          </span>
        </div>
        <div className="d-flex flex-column me-5 gap-1">
          {/* <span style={{ color: '#0B1F33' }}>{Utils.getNotificationLabelById(notification.section)}</span> */}
          <span style={{ color: '#0B1F33' }}>{Utils.getNotificationLabelById('1')}</span>
          <span className="text-secondary text-nowrap">
            {Utils.getDate(notification.createdAt, 'dd.MM.yy', true)} в{' '}
            {Utils.getDate(notification.createdAt, 'HH:mm', true)}
          </span>
        </div>
        <div className="d-flex flex-column gap-1 overflow-hidden">
          <span
            className={classNames('text-black', {
              'fw-bold': !notification.isRead,
            })}
          >
            {notification.header}
          </span>
          <div className="text-secondary overflow-hidden" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {notification.body}
          </div>
        </div>
      </Link>
    );
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState<SelectOption>(categories[0]);

  return (
    <Container className="flex-grow-1 pb-3 pb-sm-5">
      <Row>
        <Col md={9} className="pb-5">
          <h2 className="d-flex align-items-center mt-40px mb-4">
            {t('notifications')}
            {!notificationStore.isLoading && !!notificationStore.unredNotificationCount && (
              <NotificationCount count={notificationStore.unredNotificationCount} className="ms-2" />
            )}
          </h2>

          <div className="bg-white rounded shadow-main">
            <div
              className="d-flex justify-content-between p-4"
              style={{ borderBottom: `${notificationStore.hasNotifications ? 0 : '1px'} solid #E1E1E1` }}
            >
              <div className="d-flex justify-content-between align-items-center">
                {activeCategory && (
                  <DropdownList
                    list={categories}
                    value={activeCategory}
                    onSelectItem={(e) => setActiveCategory(e)}
                    isOpen={isDropdownOpen}
                    onToggleVisible={setIsDropdownOpen}
                  />
                )}
              </div>
              {!!notificationStore.unredNotificationCount && (
                <span className="text-primary cursor-pointer" onClick={onReadAllHandler}>
                  Прочитать все
                </span>
              )}
            </div>

            {notificationStore.isLoading && <Loader />}

            {!notificationStore.isLoading &&
              notificationStore.hasNotifications &&
              notificationStore.notifications?.map((notification: Notification) => (
                <div key={notification.id}>
                  <TableRow notification={notification} />
                </div>
              ))}

            {!notificationStore.isLoading && !notificationStore.hasNotifications && (
              <EmptyResult text={t('no_notifications')} />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default observer(NotificationList);
