import { useRef } from 'react';
// import { FormCheck } from 'react-bootstrap';
import {
  default as ReactSelect,
  Props,
  GroupBase,
  DropdownIndicatorProps,
  // ClearIndicatorProps,
  // OptionProps,
  components as ReactSelectComponents,
  // MultiValueProps,
} from 'react-select';
import classNames from 'classnames';
import Icon from '../Icon';
import { uid } from 'uid';
import { useTranslation } from 'react-i18next';

const plug = () => null;

// const NoOptionsMessage = <
//   Option = unknown,
//   IsMulti extends boolean = boolean,
//   Group extends GroupBase<Option> = GroupBase<Option>
// >(
//   props: NoticeProps<Option, IsMulti, Group>
// ) => <components.NoOptionsMessage {...props} children="Не найдено" />;

const DropdownIndicator = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>
) => (
  <div className={props.cx({ indicator: true, 'dropdown-indicator': true })}>
    <Icon name="small_down" width={30} height={30} />
  </div>
);

// const ClearIndicator = <
//   Option = unknown,
//   IsMulti extends boolean = boolean,
//   Group extends GroupBase<Option> = GroupBase<Option>
// >(
//   props: ClearIndicatorProps<Option, IsMulti, Group>
// ) => (
//   <ReactSelectComponents.ClearIndicator {...props}>
//     <Icon name="cross" width={22} height={22} />
//   </ReactSelectComponents.ClearIndicator>
// );

// const Option = <
//   Option = unknown,
//   IsMulti extends boolean = boolean,
//   Group extends GroupBase<Option> = GroupBase<Option>
// >(
//   props: OptionProps<Option, IsMulti, Group>
// ) => (
//   <ReactSelectComponents.Option {...props}>
//     {props.isMulti && <FormCheck inline readOnly checked={props.isSelected} className="me-2" />}
//     {props.children}
//   </ReactSelectComponents.Option>
// );

// const MultiValue = <
//   Option = unknown,
//   IsMulti extends boolean = boolean,
//   Group extends GroupBase<Option> = GroupBase<Option>
// >(
//   props: MultiValueProps<Option, IsMulti, Group>
// ) => {
//   if (props.index !== 0) return null;
//   const values = props.getValue();
//   if (values.length < 1) return null;
//   if (values.length === 1) return <span>{props.children}</span>;

//   return <span>{`Выбрано ${values.length}`}</span>;
// };

type SelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> & {
  label?: string | null;
  isCollapse?: boolean;
};

function Select<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({ className, components, label, isCollapse, ...props }: SelectProps<Option, IsMulti, Group>) {
  const { t } = useTranslation('common');
  if (props.closeMenuOnSelect === undefined && props.isMulti) props.closeMenuOnSelect = false;
  if (props.hideSelectedOptions === undefined) props.hideSelectedOptions = false;
  if (props.isSearchable === undefined) props.isSearchable = false;

  const { current: id } = useRef(uid());

  const Label = () => (
    <label htmlFor={id} className="fs-7 pb-1" style={{ color: '#0B1F33' }}>
      {label}
    </label>
  );

  return (
    <>
      {label && <Label />}
      <ReactSelect
        classNamePrefix="seq-select"
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          spacing: { ...theme.spacing, controlHeight: 52, menuGutter: 0 },
        })}
        inputId={id}
        {...props}
        className={classNames('seq-select-container', className, {
          'menu-static': isCollapse,
        })}
        noOptionsMessage={() => t('not_found')}
        components={{
          IndicatorSeparator: plug,
          // NoOptionsMessage,
          DropdownIndicator,
          // ClearIndicator,
          // Option,
          // MultiValue,
          ...components,
        }}
      />
    </>
  );
}

export default Select;
