import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Select from '../../components/Select';
import Button from '../../components/Button';

import rootStore from '../../store';
const { bookingProcessStore, mappStore } = rootStore;

function SelectTsStep() {
  const { t } = useTranslation(['booking', 'common']);

  const isDisabled = bookingProcessStore.mappOption === null;

  return (
    <div>
      <Link to="#" className="link-back my-4" onClick={() => bookingProcessStore.prevStep()}>
        {t('back', { ns: 'common' })}
      </Link>
      <h3 className="mb-32px">{t('where_will_you_cross_the_border', { ns: 'app' })}</h3>

      <div className="pb-40px">
        <div className="bg-white p-4 rounded shadow-main">
          <Select
            isCollapse
            label={t('select_app') || ''}
            placeholder={t('select_app')}
            options={mappStore.mappOptions}
            value={bookingProcessStore.mappOption}
            onChange={(e) => bookingProcessStore.setMappOption(e)}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <Button
          text={t('proceed', { ns: 'common' })}
          disabled={isDisabled}
          className="d-inline-block m-auto"
          onClick={() => bookingProcessStore.nextStep()}
        />
      </div>
    </div>
  );
}

export default observer(SelectTsStep);
