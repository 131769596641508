import client from '../../httpClient/clientV1';

const mappsUrl = '/mapps';

export type Mapp = {
  id: string;
  name: string;
  address: string;
  partPp: string;
  rushHour: string;
  workingHours: string;
};

export type CreateMappRequest = {
  id: string;
  name: string;
  address: string;
  boundaryPp: string;
  platformAddress: string;
  rushHour: string;
};

export type UpdateMappRequest = Omit<CreateMappRequest, 'id'>;

async function fetchMapps() {
  const response = await client.get<Mapp[]>(mappsUrl);
  return response.data;
}

async function fetchMappById(id: string) {
  const response = await client.get<any[]>(`/mapps/${id}`);
  return response.data;
}

async function createMapp(body: CreateMappRequest) {
  const response = await client.post('/admin/mapp', body);
  return response.data;
}

async function updateMapp(id: string, body: CreateMappRequest) {
  const response = await client.put(`/admin/mapp/${id}`, body);
  return response.data;
}

export default Object.assign({
  fetchMapps,
  fetchMappById,
  createMapp,
  updateMapp,
});
