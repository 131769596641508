import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import { Container, Row, Col, Collapse, Accordion } from 'react-bootstrap';
import BookingCard from './components/BookingCard';
import Select from '../../../../components/Select';
import { Booking } from '../../api';
import i18next from '../../../../i18n';
import Loader from '../../../../components/Loader';

import store from '../../../../store';
import EmptyResult from '../../../../components/EmptyResult';
import {
  typeOptions,
  statusOptions,
  timeOptions,
  transportClassOptions as classOptions,
} from '../../../../models/static';
import { filterStore } from './store';
import Button from '../../../../components/Button';
const { bookingStore } = store;

const transportClassOptions = [{ label: 'all', value: 'all' }, ...classOptions];

function BookingList() {
  const { t } = useTranslation(['header', 'booking', 'common', 'transport']);
  const navigation = useNavigate();

  useEffect(() => {
    bookingStore.fetchBookings().then(() => filterStore.initFilter(bookingStore.bookings));
  }, []);

  const isHaveBookings = !bookingStore.isLoading && !!filterStore.filteredOptions?.length;
  const isNotHaveBookings = !bookingStore.isLoading && !filterStore.filteredOptions?.length;

  const Filters = observer(() => (
    <>
      <Select
        isCollapse
        options={typeOptions}
        value={filterStore.options.typeOption}
        formatOptionLabel={({ label }) => <>{t(label, { ns: 'booking' })}</>}
        onChange={(e) => filterStore.onChangeOption('typeOption', e)}
        className="mb-3 mb-md-4"
      />
      <Select
        isCollapse
        options={statusOptions}
        value={filterStore.options.statusOption}
        formatOptionLabel={({ label }) => <>{t(label, { ns: 'booking' })}</>}
        onChange={(e) => filterStore.onChangeOption('statusOption', e)}
        className="mb-3 mb-md-4"
      />
      <Select
        isCollapse
        options={timeOptions}
        value={filterStore.options.timeOption}
        formatOptionLabel={({ label }) => <>{t(label, { ns: 'booking' })}</>}
        onChange={(e) => filterStore.onChangeOption('timeOption', e)}
        className="mb-3 mb-md-4"
      />
      <Select
        isCollapse
        options={transportClassOptions}
        value={filterStore.options.transportClassOption}
        formatOptionLabel={({ label }) => <>{t(label, { ns: 'transport' })}</>}
        onChange={(e) => filterStore.onChangeOption('transportClassOption', e)}
      />
    </>
  ));

  return (
    <Container className="flex-grow-1 pb-3 pb-sm-5">
      <Row>
        <Col xs={12} lg={9}>
          <h2 className="mt-40px mb-4">{t('booking')}</h2>

          <div
            className="d-none d-md-flex justify-content-between rounded py-28px px-4 mb-4"
            style={{ border: '1px dashed rgba(29, 93, 235, 0.18)' }}
          >
            <span className="text-secondary">{t('add_new_booking', { ns: 'booking' })}</span>

            <Link to="/booking-process" className="link-primary fs-6">
              {t('add', { ns: 'common' })}
            </Link>
          </div>

          <Button
            text={t('add', { ns: 'common' })}
            className="d-flex d-md-none justify-content-center mb-3 w-100"
            onClick={() => navigation('/booking-process')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={9}>
          <div className="bg-white rounded shadow-main p-4 mb-4 d-none d-lg-block">
            <Form.Control
              placeholder={t('search', { ns: 'common' }) || ''}
              type="search"
              value={filterStore.searchQuery}
              onChange={(e) => filterStore.setSearchQuery(e.target.value)}
            />
          </div>

          <div className="d-block d-lg-none mb-3">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>{t('filters', { ns: 'booking' })}</Accordion.Header>
                <Accordion.Body>
                  <Filters />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          <div className="mb-4">
            {bookingStore.isLoading && (
              <div className="bg-white rounded shadow-main">
                <Loader />
              </div>
            )}

            {isHaveBookings && (
              <>
                {filterStore.filteredOptions.map((booking: Booking, index: number) => (
                  <div key={index}>
                    <BookingCard booking={booking} />
                  </div>
                ))}
              </>
            )}

            {isNotHaveBookings && (
              <div className="bg-white rounded shadow-main">
                <EmptyResult text={i18next.t('no_reservations_were_found', { ns: 'booking' })} />
              </div>
            )}
          </div>
        </Col>
        <Col lg={3} className="d-none d-lg-block">
          <div className="bg-white rounded shadow-main p-3 mb-3 p-md-4 mb-md-4 ps-2">
            <Filters />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default observer(BookingList);
