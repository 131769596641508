import { action, makeAutoObservable } from 'mobx';
import { SelectOptionExtended } from '../../models';
import { RootStore } from '../../store';
import Api, { Mapp } from './api';

export class MappStore {
  rootStore: RootStore;

  mapps: Mapp[] = [];
  isLoading = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  async fetchMapps() {
    this.setIsLoading(true);

    await Api.fetchMapps()
      .then(
        action('fetchSuccess', (response: { data: Mapp[] }) => this.setMapps(response.data)),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  setMapps(mapps: Mapp[]) {
    this.mapps = mapps;
  }

  async fetchMappById(mappId: string) {
    this.setIsLoading(true);

    return await Api.fetchMappById(mappId)
      .then(
        action('fetchSuccess', (response: { data: Mapp[] }) => response.data),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  async createMapp(mapp: any) {
    this.setIsLoading(true);

    return await Api.createMapp(mapp)
      .then(
        action('fetchSuccess', () => true),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  async updateMapp(id: string, mapp: any) {
    this.setIsLoading(true);

    return await Api.updateMapp(id, mapp)
      .then(
        action('fetchSuccess', () => true),
        action('fetchError', (error: unknown) => console.error(error))
      )
      .finally(() => this.setIsLoading(false));
  }

  get mappOptions(): SelectOptionExtended[] {
    return this.mapps.map((mapp) => ({
      label: mapp.name,
      value: mapp.id,
      ...mapp,
    }));
  }
}
