import { Container, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import store from '../../../../store';
import { useEffect } from 'react';
import Loader from '../../../../components/Loader';

type RouterParams = {
  id: string;
};

const { notificationStore } = store;

function TransportSingle() {
  const { id } = useParams<RouterParams>();

  const { t } = useTranslation(['transport', 'common']);

  useEffect(() => {
    if (!id) return;

    notificationStore.fetchNotificationById(id).then(() => {
      if (notificationStore.notification?.isRead) return;

      notificationStore.readNotifications([id]).then(() => {
        notificationStore.fetchNotifications();
      });
    });

    return () => {
      notificationStore.setNotification();
    };
  }, [id]);

  return (
    <>
      <Container className="flex-grow-1 pb-3 pb-sm-5">
        <Row>
          <Col md={9}>
            <Link to="/notification" className="link-back my-4">
              {t('back', { ns: 'common' })}
            </Link>
            <div className="bg-white p-4 shadow-main rounded d-flex flex-column" style={{ marginBottom: '4rem' }}>
              {notificationStore.isLoading && <Loader />}

              {!notificationStore.isLoading && (
                <>
                  <span className="fs-5 fw-bold text-black mb-1">{notificationStore.notification?.header}</span>
                  <span className="fs-6 text-secondary mb-4">
                    Отправитель: {notificationStore.notification?.header}
                  </span>
                  <span
                    className="fs-6"
                    // dangerouslySetInnerHTML={{ __html: notification.description?.replaceAll('\\n', '</br>') }}
                    // dangerouslySetInnerHTML={{ __html: notificationStore.notification?.header }}
                  >
                    {notificationStore.notification?.header}
                  </span>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default observer(TransportSingle);
